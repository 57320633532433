<template>
  <f7-page name="sectionform">
    <f7-navbar v-bind:title="'Sektion: Polter ' + polter.polter"
      v-bind:subtitle="(polter.sortiment || '') + ' ' + (polter.waldort || '')" back-link-force
      v-bind:back-link-url="'/list/' + polter.list_id + '/'" back-link="Back">
      <div class="right">
        <button @click="delSection(activesection)" class=" button" v-if='activesection > 0'>
          <f7-icon material="delete_outline"></f7-icon>
        </button>
      </div>
    </f7-navbar>
    <f7-toolbar class="keyboard" bottom>
      <f7-row class="keyboard__container">
        <f7-col class="keyboard__container-inner">
          <!--Input rows-->
          <f7-row class="keyboard__inputs" no-gap>
            <f7-col>
              <f7-input ref="polter_depth" outline inputStyle="height:40px;" type="text" placeholder="Poltertiefe"
                input_id="polter_depth" :value="section.polter_depth"
                :class="{ 'highlight_small': 'polter_depth' == focuselmt }"
                @input="section.polter_depth = $event.target.value" @blur="handleBlur('polter_depth')"
                @focus="handleFocus('polter_depth')"></f7-input>
            </f7-col>
            <f7-col>
              <f7-input ref="section_length" outline inputStyle="height:40px;" type="text" placeholder="Sektionslänge"
                input_id="section_length" :value="section.section_length"
                :class="{ 'highlight_small': 'section_length' == focuselmt }"
                @input="section.section_length = $event.target.value" @blur="handleBlur('section_length')"
                @focus="handleFocus('section_length')"></f7-input>
            </f7-col>
            <f7-col>
              <f7-input ref="section_height" outline inputStyle="height:40px" type="text" readonly
                placeholder="Sektionshöhe" :value="section.section_height"
                @input="section.section_height = $event.target.value"
                :class="{ 'highlight_small': 'section_height' == focuselmt }" @blur="handleBlur('section_height')"
                @focus="handleFocus('section_height')"></f7-input>
            </f7-col>
          </f7-row>

          <!--Keyboard-->
          <f7-row class="keyboard__keyboard">
            <f7-col class="keyboard__inputs2" width="45" style="margin: 0px">
              <f7-list no-hairlines style="margin: 0px">
                <f7-list-input label="Raumübermass" type="text" placeholder="Raumübermass" :value="sum_rumass"
                  @input="sum_rumass = $event.target.value" validate></f7-list-input>
                <f7-list-input label="Umrechnungsfaktor" type="text" placeholder="Raumübermass" :value="sum_faktor"
                  @input="sum_faktor = $event.target.value" validate></f7-list-input>
                <f7-list-input label="Raummeter" type="text" placeholder="Raummeter" :value="comp_totale"
                  disabled></f7-list-input>
                <f7-list-input label="Festmeter" type="text" placeholder="Festmeter" :value="comp_fm"
                  disabled></f7-list-input>
              </f7-list>
            </f7-col>
            <f7-col class="keyboard__keyboard-numblock">
              <f7-row no-gap>
                <f7-col class="button-column" width="33">
                  <button class="col-button col-button--big" @click="chg_value('7')"><span
                      class="nichtmarkierbar">7</span></button>
                </f7-col>
                <f7-col class="button-column" width="33">
                  <button class="col-button col-button--big" @click="chg_value('8')"><span
                      class="nichtmarkierbar">8</span></button>
                </f7-col>
                <f7-col class="button-column" width="33">
                  <button class="col-button col-button--big" @click="chg_value('9')"><span
                      class="nichtmarkierbar">9</span></button>
                </f7-col>
              </f7-row>
              <f7-row no-gap>
                <f7-col class="button-column" width="33">
                  <button class="col-button col-button--big" @click="chg_value('4')"><span
                      class="nichtmarkierbar">4</span></button>
                </f7-col>
                <f7-col class="button-column" width="33">
                  <button class="col-button col-button--big" @click="chg_value('5')"><span
                      class="nichtmarkierbar">5</span></button>
                </f7-col>
                <f7-col class="button-column" width="33">
                  <button class="col-button col-button--big" @click="chg_value('6')"><span
                      class="nichtmarkierbar">6</span></button>
                </f7-col>
              </f7-row>
              <f7-row no-gap>
                <f7-col class="button-column" width="33">
                  <button class="col-button col-button--big" @click="chg_value('1')"><span
                      class="nichtmarkierbar">1</span></button>
                </f7-col>
                <f7-col class="button-column" width="33">
                  <button class="col-button col-button--big" @click="chg_value('2')"><span
                      class="nichtmarkierbar">2</span></button>
                </f7-col>
                <f7-col class="button-column" width="33">
                  <button class="col-button col-button--big" @click="chg_value('3')"><span
                      class="nichtmarkierbar">3</span></button>
                </f7-col>
              </f7-row>
              <f7-row no-gap>
                <f7-col class="button-column" width="33">
                  <button class="col-button col-button--big" @click="chg_value('0')"><span
                      class="nichtmarkierbar">0</span></button>
                </f7-col>
                <f7-col class="button-column" width="33">
                  <button class="col-button col-button--big" @click="chg_value('.')"><span class="nichtmarkierbar"
                      style="font-weight: 900;">.</span></button>
                </f7-col>
                <f7-col class="button-column" width="33">
                  <button class="col-button col-button--big" @click="chg_remove()"><span><f7-icon
                        material="keyboard_backspace"></f7-icon></span></button>
                </f7-col>
              </f7-row>
            </f7-col>
          </f7-row>

          <!--Botton buttons-->
          <f7-row class="keyboard__buttons" no-gap>
            <f7-col class="button-column_sec">
              <button class="col-button" @click="chgbackward">
                <f7-icon material="keyboard_arrow_left"></f7-icon>
              </button>
            </f7-col>
            <f7-col class="button-column_sec">
              <button class="col-button" @click="chgforward">
                <f7-icon material="keyboard_arrow_right"></f7-icon>
              </button>
            </f7-col>
            <f7-col class="button-column_sec">
              <button class="col-button" style="background-color: greenyellow;" @click="savesection">
                <f7-icon material="keyboard_return"></f7-icon>
              </button>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>

    </f7-toolbar>

    <f7-row style="margin-bottom: 330px">
      <f7-col>
        <f7-block>
          <f7-row>
            <f7-col>Tiefe</f7-col>
            <f7-col>Länge</f7-col>
            <f7-col>Höhe</f7-col>
            <f7-col>RM</f7-col>
          </f7-row>
          <f7-row v-for="(section) in sections" v-bind:key="section.id" @click='editSection(section)'
            :class="[{ 'highlight': section.id == activesection }, { 'zebra': section.id != activesection }]"
            style="border-bottom: 1px solid lightgrey">
            <f7-col style="border-right: 1px solid lightgrey">{{ section.polter_depth }}</f7-col>
            <f7-col style="border-right: 1px solid lightgrey">{{ section.section_length }}</f7-col>
            <f7-col style="border-right: 1px solid lightgrey">{{ section.section_height }}</f7-col>
            <f7-col style="border-right: 1px solid lightgrey">{{ section.section_rm }}</f7-col>
          </f7-row>
        </f7-block>
      </f7-col>
    </f7-row>

  </f7-page>
</template>
<script>
export default {
  data: function () {
    return {
      polter_id: (this.$f7route.params.polter),
      polter: { polter: null },
      activesection: 0,
      focuselmt: null,
      section: {
        polter_id: parseInt(this.$f7route.params.polter),
        section_length: null,
        section_height: null,
        polter_depth: null,
      },
      sum_rumass: 0,
      sum_faktor: 0.7,
      sum_rm: 0,
      sum_fm: 0,
      sections: null

    }
  },
  beforeRouteLeave(to, from, next) {
    this.leaving = true;
    setTimeout(() => {
      this.left = true;
      next();
    }, 550);
  },
  mounted() {
    this.$f7ready(async (f7) => {
      this.polter = await this.$dbnew.getPolterbyId(parseInt(this.polter_id))
      this.sections = await this.$dbnew.getSectionsbyPolterid(parseInt(this.polter_id))

      this.focuselmt = 'polter_depth'
      this.$refs.polter_depth.$refs.inputEl.focus()
    })
  },

  async destroyed() {
    this.calcSum();
  },

  computed: {
    comp_totale: function () {
      var rm = 0
      if (this.sections != null) {
        this.sections.forEach((section, i) => {
          rm = rm + section.section_rm
        })
      }
      this.sum_rm = this.round(rm, 3)
      return this.round(rm, 3)
    },
    comp_fm: function () {
      this.sum_fm = this.round(this.sum_rm * (100 - this.sum_rumass) / 100 * this.sum_faktor, 3)
      return this.sum_fm
    }
  },



  methods: {
    round(x, n) {
      var a = Math.pow(10, n)
      return (Math.round(x * a) / a)
    },
    async savesection() {
      delete this.section.id
      this.section.typ = 'section';
      this.section.section_rm = parseFloat(this.section.polter_depth) * parseFloat(this.section.section_length) * parseFloat(this.section.section_height)
      if (this.activesection === 0) {
        await this.$dbnew.addSection(this.section)
      } else {
        await this.$dbnew.updateSectionbyId(parseInt(this.activesection), this.section)
        this.activesection = 0
      }
      this.focuselmt = 'section_height'
      this.section.section_height = null
      this.sections = await this.$dbnew.getSectionsbyPolterid(parseInt(this.polter_id))
      this.calcSum();
    },
    editSection(section) {
      if (this.activesection === parseInt(section.id) && this.activesection > 0) {
        this.activesection = 0
        this.focuselmt = 'section_height'
        this.section.section_height = null
      } else {
        this.activesection = parseInt(section.id)
        this.section = { ...section }
      }
    },

    async delSection(sectionid) {
      await this.$dbnew.delSectionbyId(parseInt(sectionid))
      this.focuselmt = 'section_height'
      this.section.section_height = null
      this.activesection = 0
      this.calcSum();
      this.sections = await this.$dbnew.getSectionsbyPolterid(parseInt(this.polter_id))
    },

    async calcSum() {
      var t = await this.$dbnew.getSumSectionbyPolterid(parseInt(this.polter_id))
      var id = null
      var data = {
        polter_id: parseInt(this.polter_id),
        rumass: this.sum_rumass,
        faktor: this.sum_faktor,
        rm: this.sum_rm,
        fm: this.sum_fm,
        typ: 'sum'
      }

      if (t !== undefined) {
        await this.$dbnew.updateSectionbyId(t.id, data)
      } else {
        await this.$dbnew.addSection(data)
      }
    },

    chg_value(wert) {
      if (this.section[this.focuselmt] == null) {
        this.section[this.focuselmt] = ''
      }
      this.section[this.focuselmt] = this.section[this.focuselmt] + wert
      this.$refs[this.focuselmt].$refs.inputEl.focus()
    },
    chg_remove() {
      if (this.section[this.focuselmt] == null) {
        this.section[this.focuselmt] = ''
      }
      this.section[this.focuselmt] = this.section[this.focuselmt].slice(0, -1)
      this.$refs[this.focuselmt].$refs.inputEl.focus()
    },
    chgforward() {
      switch (this.focuselmt) {
        case 'polter_depth':
          this.focuselmt = 'section_length'
          break
        case 'section_length':
          this.focuselmt = 'section_height'
          break
        case 'section_height':
          this.focuselmt = 'polter_depth'
          break
      }
    },
    chgbackward() {
      switch (this.focuselmt) {
        case 'polter_depth':
          this.focuselmt = 'section_height'
          break
        case 'section_length':
          this.focuselmt = 'polter_depth'
          break
        case 'section_height':
          this.focuselmt = 'section_length'
          break
      }
    },
    handleBlur(e) {
      this.focuselmt = e
    },
    handleFocus(e) {
      this.focuselmt = e
      document.activeElement.blur()
    }
  }

}
</script>
<style lang="less" scoped>
.keyboard {
  height: auto !important;
  background: #FFFFFF !important;
  color: black;

  >.toolbar-inner {
    position: static;
    padding: 10px;
  }

  &__container {
    height: 100%;
    align-items: stretch;
    max-width: 600px;
    margin: 0 auto;
  }

  &__container-inner {
    display: flex;
    flex-direction: column;

    >div {
      padding: 0 0 10px;

      &:last-child {
        padding: 0;
      }
    }
  }

  &__inputs {
    .col {
      padding: 0 5px 0 0;

      &:last-child {
        padding: 0;
      }

      input {
        font-size: 13px;
      }
    }
  }

  &__info {
    [class*="col-"] {
      white-space: nowrap;

      &:last-child {
        text-align: right;
      }
    }
  }

  &__keyboard {
    margin: 0 -5px;

    >.col {
      height: 100%;
      padding: 0 5px;
    }

    i.icon {
      display: inline-block;
    }

    &-tree {
      width: 15% !important;

      >.row {
        overflow: scroll;
        height: 200px;
        min-height: 100%;
      }
    }

    &-quality {
      width: 30% !important;

      >.row {
        height: 100%;
      }
    }

    &-numblock {
      width: 55% !important;
      height: 33.3%;
    }
  }

  &__buttons {
    justify-content: flex-start;

    .button-column_sec {

      &:nth-child(1),
      &:nth-child(2) {
        width: calc(22.5% - 2.5px);
      }

      &:nth-child(2) {
        margin-right: 10px;
      }

      &:nth-child(3) {
        width: calc(18.3333% - 2px);
        margin-left: calc(36.6666% - 3px)
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.button-column_sec {
  padding: 2px;
}

.col-button {
  border: 1px solid black;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: calc(20% - 5px);
  min-height: 40px;
  font-size: 20px;
  border-radius: 4px;
  cursor: pointer;

  &:focus {
    outline: none;
    animation: flashup 0.5s ease forwards
  }


  &--big {
    height: 33%;
    min-height: 60px;
    font-size: 40px;
  }
}

@keyframes flashup {
  0% {
    background-color: grey;
  }

  20% {
    background-color: darkgrey;
  }

  10% {
    background-color: grey;
  }
}
</style>
