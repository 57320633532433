<template>
  <f7-page name="polterform">
    <f7-navbar v-bind:title="edit? 'Polter aktualisieren': 'Polter erstellen'" back-link-force v-bind:back-link-url="'/list/' + list_id + '/'" back-link="Back"></f7-navbar>
    <form @submit.prevent="savepolter">
      <f7-block-title>Holzliste {{list.number}}</f7-block-title>
      <f7-block>
          <p>Die Daten können nachträglich noch geändert werden. Wichtig: Jeweils pro Sortiment und Standort ein Polter erfassen!</p>
        </f7-block>
      <f7-list no-hairlines-md>
        <f7-list-input
          label="Polternummer"
          type="text"
          placeholder="Polternummer"
          :value="polter.polter"
          @input="polter.polter = $event.target.value"
          required
          validate
          clear-button
        ></f7-list-input>
        <f7-list-input
          label="Sortiment"
          type="text"
          placeholder="Sortiment"
          :value="polter.sortiment"
          @input="polter.sortiment = $event.target.value"
          required
          validate
        ></f7-list-input>
        <f7-list-input
          label="Erfassungsmethode"
          type="select"
          :value="polter.erfassungsmethode"
          @input="polter.erfassungsmethode = $event.target.value"
        >
          <option value="log">Einzelstamm</option>
          <option value="section">Sektionsraummass</option>
          <option value="estimate">Schätzwert</option>
        </f7-list-input>
        <f7-list-input
          v-if="polter.erfassungsmethode == 'estimate'"
          label="Schätzwert"
          type="number"
          placeholder="0"
          :value="polter.schaetzwert"
          @input="polter.schaetzwert = $event.target.value"
          required
        >
          <f7-input
            label="Einheit"
            type="select"
            :value="polter.einheit"
            @input="polter.einheit = $event.target.value"
            slot="content-end"
            style="width:50%; vertical-align: bottom; margin-right: 15px; margin-top: 20px"
            inputStyle="padding-left: 5px;"
            required
          >
            <option value="srm">srm</option>
            <option value="tolutro">to lutro</option>
            <option value="toatro">to atro</option>
            <option value="ster">ster</option>
            <option value="fm">fm</option>
          </f7-input>
        </f7-list-input>
        <f7-list-input
          v-if="polter.erfassungsmethode == 'estimate'"
          label="Umrechnungsfaktor"
          type="number"
          placeholder="0"
          step="0.01"
          :value="polter.umrechnungsfaktor"
          @input="polter.umrechnungsfaktor = $event.target.value"
        >
        </f7-list-input>
        <f7-list-item 
          v-if="polter.erfassungsmethode == 'estimate' && polter.fm" 
          header="Umrechnung in fm" 
          v-bind:title="(polter.fm) + ' fm'"
        >
        </f7-list-item>
        <f7-list-input
          type="text"
          label="Waldort"
          placeholder="Waldort"
          :value="polter.waldort"
          @input="polter.waldort = $event.target.value"
          clear-button
        ></f7-list-input>
        <f7-list-input
          type="textarea"
          label="Kommentar"
          placeholder="Kommentar"
          :value="polter.comment"
          @input="polter.comment = $event.target.value"
          clear-button
        ></f7-list-input>
          <f7-list-item header="Position" v-bind:title="polter.lang +' / '+polter.long" v-if="polter.long"></f7-list-item>
          <f7-list-item header="Position" title="Positionsermittlung läuft" v-else-if="gettingLocation"></f7-list-item>
          <f7-list-item header="Fehler" :title="errorStr" v-else></f7-list-item>
          <f7-list-item header="Genauigkeit" v-bind:title="parseInt(location.coords.accuracy) + 'm'" v-if="location.coords.accuracy"></f7-list-item>
          <f7-list-button v-if="timeleft==0" title="Position neu ermitteln" @click="get_location_wrapper()" color="green" icon="pi pi-times">
            <template>
              <f7-icon material="location_on"></f7-icon>
                  Position neu ermitteln
            </template>
          </f7-list-button>
          <f7-list-item v-if="timeleft!=0" header="Positionsbestimmung läuft" v-bind:title="'noch ' + timeleft + ' Sekunden' "></f7-list-item>
      </f7-list>
      <f7-block>
          <f7-row>
              <f7-col width="20"/>
              <f7-col width="60"><f7-button class="col" round fill type="submit">{{ edit ? 'Polter aktualisieren': 'Polter erstellen' }}</f7-button></f7-col>
              <f7-col width="20"/>
          </f7-row>
      </f7-block>
    </form>
  </f7-page>
</template>
<script>
export default {
  data: function () {
    return {
      list_id: this.$f7route.params.list,
      polter_id: this.$f7route.params.id,
      list: { number: null, comment: null },
      location: { coords: { longitude: null, latitude: null } },
      gettingLocation: false,
      errorStr: null,
      edit: false,
      timeleft: 0,
      polter: {
        list_id: parseInt(this.$f7route.params.list),
        polter: null,
        comment: null,
        waldort: null,
        sortiment: null,
        erfassungsmethode: 'log',
        schaetzwert: null,
        einheit: null,
        umrechnungsfaktor: null,
        fm: null,
        long: null,
        lang: null
      },
      settings: JSON.parse(localStorage.settings || "{'stehendart': 'cm', 'factor':{'srm': 0.4, 'toatro': 0.7, 'tolutro': 0.9}}")
    }
  },
  mounted () {
    this.$f7ready(async (f7) => {
      this.list = this.list = await this.$dbnew.getListbyId(this.list_id)
      await this.$root.checkpolters();
      if (this.polter_id != null) {
        this.edit = true
        this.polter = await this.$dbnew.getPolterbyId(parseInt(this.polter_id))
      } else {
        await this.get_location_wrapper()

        var polters = JSON.parse(localStorage.polters ||'[]');
        polters.sort();
        if (polters.length > 1 && (localStorage.user_id || localStorage.user)) {
          this.polter.polter = polters[0]
        } else {
          this.polter.polter = ""
        }

      }
    })
  },
  watch: {
    polter: {
      deep: true,
      handler(polter, alt) {
        if (polter.einheit && !polter.umrechnungsfaktor) {
          if (polter.einheit == 'fm') {
            polter.umrechnungsfaktor = 1
          } else {
            polter.umrechnungsfaktor = this.settings.factor[polter.einheit]
          }
        }
        if (polter.umrechnungsfaktor && polter.schaetzwert) {
          polter.fm = this.round(polter.schaetzwert * polter.umrechnungsfaktor,3)
        }
        if(polter.schaetzwert == 0) {
          polter.fm = 0
        }
      }
    }
  },
  methods: {
    async savepolter () {
      if (this.edit === false) {
        var data = await this.$dbnew.addPolter(this.polter)

        // remove Polter
        console.log('polter', this.polter)
        var polters = JSON.parse(localStorage.polters ||'[]');
        var polter = this.polter.polter
        polters = polters.filter(function(e) { return e !== polter })
        localStorage.polters = JSON.stringify(polters);

        this.$root.checkpolters();


        if (this.polter.erfassungsmethode === 'log') {
          this.$f7.views.current.router.navigate('/logform/' + data + '/')
        } else if (this.polter.erfassungsmethode === 'section') {
          this.$f7.views.current.router.navigate('/sectionform/' + data + '/')
        } else {
          this.$f7.views.current.router.navigate('/list/' + this.list_id + '/')
        }
      } else {
        await this.$dbnew.updatePolterbyId(parseInt(this.polter_id), this.polter)
        this.$f7.views.current.router.navigate('/list/' + this.list_id + '/')
      }
    },
    async get_location_wrapper () {
      await this.get_location(function (pos) {
        })
    },
    get_location (callback) {
      var options = {
        enableHighAccuracy: true,
        timeout: 150000,
        maximumAge: 0
      };
      if (!('geolocation' in navigator)) {
        this.errorStr = 'Geolocation is not available.'
        console.log('err')
        return
      }

      this.gettingLocation = true

      /*navigator.geolocation.getCurrentPosition(pos => {
        console.log(pos)
        this.gettingLocation = false
        this.location = pos
        this.polter.long = this.location.coords.longitude
        this.polter.lang = this.location.coords.latitude
      }, err => {
        this.gettingLocation = false
        this.errorStr = err.message
        console.log(err)
      }, options)
      */
      var watchID = navigator.geolocation.watchPosition( pos => {
        console.log(pos)
        this.gettingLocation = false
        this.location = pos
        this.polter.long = this.location.coords.longitude
        this.polter.lang = this.location.coords.latitude
      }, err => {
        this.gettingLocation = false
        this.errorStr = err.message
        console.log(err)
      }, options)
      setTimeout( function() { navigator.geolocation.clearWatch( watchID ); }, 10000 );
      this.timeleft = 9;
      var that = this;
      var downloadTimer = setInterval(function(){
        if(that.timeleft <= 0){
          clearInterval(downloadTimer);
        } else {
          that.timeleft -= 1;
        }
      }, 1000);
    },
    round (x, n) {
      var a = Math.pow(10, n)
      return (Math.round(x * a) / a)
    },
  }
}
</script>
