var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-page',{attrs:{"name":"export"}},[_c('f7-navbar',{attrs:{"title":"Exportieren","back-link":"Back","back-link-force":"","back-link-url":"/","subtitle":"Listen ins MobiPolter Desktop hochladen"}}),_vm._v(" "),_c('f7-block',{attrs:{"strong":""}},[_vm._v("\n    Zum Hochladen der Listen ist ein Account Voraussetzung. Registriere dich unter mobipolter.ch\n  ")]),_vm._v(" "),_c('f7-block-title',[_vm._v("Auswahl der "),_c('b',[_vm._v("Holzlisten")])]),_vm._v(" "),_c('f7-block',{staticClass:"no-padding-horizontal",attrs:{"strong":""}},[_c('f7-treeview',_vm._l((_vm.lists),function(item,index){return _c('f7-treeview-item',{key:index,attrs:{"opened":"","label":(item.number || '-').toString() + ' ' + (item.comment || '').toString().substring(0, 50),"icon-f7":"document_text_fill"}},[_c('f7-checkbox',{attrs:{"slot":"content-start","checked":Object.values(_vm.lists[index]['selected']).indexOf(false) <= 0 && Object.values(_vm.lists[index]['selected']).indexOf(true) >= 0,"indeterminate":Object.values(_vm.lists[index]['selected']).indexOf(false) >= 0 && Object.values(_vm.lists[index]['selected']).indexOf(true) >= 0},on:{"change":(e) => {
        Object.keys(_vm.lists[index]['selected']).forEach(k => _vm.lists[index]['selected'][k] = e.target.checked);
        _vm.prepare_list();
      }},slot:"content-start"}),_vm._v(" "),_vm._l((item.polter),function(polter,indexpolter){return _c('f7-treeview-item',{key:indexpolter,attrs:{"icon-f7":"location"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n            "+_vm._s((polter.polter || '-').toString())+" "),(polter.export)?_c('small',[_vm._v("(bereits exportiert)")]):_vm._e()]},proxy:true}],null,true)},[_vm._v(" "),_c('f7-checkbox',{attrs:{"slot":"content-start","checked":_vm.lists[index]['selected'][indexpolter]},on:{"change":((e) => {
        _vm.lists[index]['selected'][indexpolter] = e.target.checked
        _vm.prepare_list();
      })},slot:"content-start"})],1)})],2)}),1)],1),_vm._v(" "),_c('f7-block-title',[_vm._v("Auswahl der "),_c('b',[_vm._v("Stehenderfassungsgruppen")])]),_vm._v(" "),_c('f7-block',{staticClass:"no-padding-horizontal",attrs:{"strong":""}},[_c('f7-treeview',_vm._l((_vm.standing),function(item,index){return _c('f7-treeview-item',{key:index,attrs:{"opened":"","icon-f7":"tree"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n          "+_vm._s(item.short_desc)+" "),(item.export)?_c('small',[_vm._v("(bereits exportiert)")]):_vm._e()]},proxy:true}],null,true)},[_vm._v(" "),_c('f7-checkbox',{attrs:{"slot":"content-start","checked":_vm.standing[index]['selected']},on:{"change":(e) => {
        _vm.standing[index]['selected'] = e.target.checked
        _vm.prepare_standing();
      }},slot:"content-start"})],1)}),1)],1),_vm._v(" "),_c('f7-sheet',{staticClass:"demo-sheet-swipe-to-close",staticStyle:{"height":"auto","--f7-sheet-bg-color":"#fff"},attrs:{"backdrop":""}},[_c('f7-page-content',[_c('f7-block-title',[_vm._v("Login")]),_vm._v(" "),_c('f7-list',{attrs:{"form":""}},[_c('f7-list-input',{attrs:{"type":"text","name":"username","placeholder":"Benutzernamen","value":_vm.username},on:{"input":function($event){_vm.username = $event.target.value}}}),_vm._v(" "),_c('f7-list-input',{attrs:{"type":"password","name":"password","placeholder":"Passwort","value":_vm.password},on:{"input":function($event){_vm.password = $event.target.value}}})],1),_vm._v(" "),_c('f7-list',[_c('f7-block-footer',[_vm._v("\n          "+_vm._s(_vm.status)+"\n        ")])],1),_vm._v(" "),_c('f7-block',[_c('f7-row',[_c('f7-col',{attrs:{"width":"20"}}),_vm._v(" "),_c('f7-col',{attrs:{"width":"60"}},[_c('f7-button',{attrs:{"round":"","fill":"","title":"Sign In"},on:{"click":_vm.liste_hochladen}},[_vm._v("Login")])],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"20"}})],1)],1)],1)],1),_vm._v(" "),_c('f7-block',[_c('f7-row',[_c('f7-col',{attrs:{"width":"20"}}),_vm._v(" "),_c('f7-col',{attrs:{"width":"60"}},[_c('f7-button',{attrs:{"round":"","fill":"","sheet-open":".demo-sheet-swipe-to-close"}},[_vm._v("Auswahl\n          hochladen")])],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"20"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }