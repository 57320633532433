<template>
  <f7-page :page-content="false">
    <f7-navbar title="Einstellungen" back-link-force back-link="Back">
    </f7-navbar>
    <f7-toolbar tabbar bottom>
      <f7-link tab-link="#tab-1" tab-link-active>Holzarten</f7-link>
      <f7-link tab-link="#tab-2">Einstellungen</f7-link>
    </f7-toolbar>
    <f7-tabs animated>
      <!-- Holzarten --->
      <f7-tab id="tab-1" class="page-content" tab-active>
        <f7-block-title>Holzarten</f7-block-title>
        <f7-list sortable class="sortable-enabled" @sortable:sort="onSort" media-list>
          <f7-list-item v-for="(hadet, index) in holzarten" :key="hadet.holzart"
            swipeout
            @swipeout:deleted="onDelete(index)"
            >
            <template #title>
              <f7-row no-gap>
                <f7-col width="10">
                  {{ hadet.holzart }}
                </f7-col>
                <f7-col width="25">
                  <img src="static/icons/liegend.png" style="width: 20px" />
                  <f7-toggle 
                    :value="hadet.holzart"
                    :checked="hadet.liegend"
                    @change="hadet.liegend = $event.target.checked"
                    />
                </f7-col>
                <f7-col width="25">
                  <img src="static/icons/stehend.png" style="width: 20px" />
                  <f7-toggle 
                    :value="hadet.holzart"
                    :checked="hadet.stehend"
                    @change="hadet.stehend = $event.target.checked"
                    />
                </f7-col>
                <f7-col width="20">
                  <span v-if="denzin.includes(hadet.holzart)"><small>Denzin</small></span>
                </f7-col>
                <f7-col width="20">
                  <span v-if="rabzug[hadet.holzart]"><small>Rinden</small></span>
                </f7-col>
              </f7-row>
            </template>
            <f7-swipeout-actions right>
              <f7-swipeout-button delete overswipe color="red">
                <f7-icon material="delete_outline"></f7-icon>
              </f7-swipeout-button>
            </f7-swipeout-actions>
          </f7-list-item>
        </f7-list>
        <f7-block>
          <f7-row>
              <f7-col width="50">
                 <f7-button round text="Zurücksetzen" @click="holzartreset()"/>
              </f7-col>
              <f7-col width="50">
                 <f7-button round fill text="Neue Holzart" @click="addholzart($event)"/>
              </f7-col>
          </f7-row>
        </f7-block>
       </f7-tab>
      <!-- Einstellungen --->
      <f7-tab id="tab-2" class="page-content">
        <f7-block-title medium>Einstellungen</f7-block-title>
        <f7-block-title>Stehenderfassung</f7-block-title>
        <f7-block-header>Standardaufnahmeart mit Denzinformel</f7-block-header>
        <f7-block inset>Bitte beachte: Bei der Stehenderfassung mit Tarif ist nur die Kluppenstufe möglich</f7-block>
        <f7-list>
          <f7-list-item value="cm" :checked="settings.stehendart == 'cm'" @change="settings.stehendart = 'cm'" radio radio-icon="start" title="in cm" name="demoradiostart"></f7-list-item>
          <f7-list-item value="kluppe" :checked="settings.stehendart == 'kluppe'" @change="settings.stehendart = 'kluppe'" radio radio-icon="start" title="mit Kluppenstufe" name="demoradiostart"></f7-list-item>
        </f7-list>
        
        <hr/>
        <f7-block-title>Umrechnungsfaktoren</f7-block-title>
        <f7-block-header>Standardumrechnungsfaktoren in fm</f7-block-header>
        <f7-list inline-labels>
          <f7-list-input
            v-if="!!settings.factor"
            label="srm"
            type="number"
            step=0.001
            :value="settings.factor.srm"
            @input="settings.factor.srm = $event.target.value"
            required
            validate
          ></f7-list-input>
          <f7-list-input
            v-if="!!settings.factor"
            label="to atro"
            type="number"
            step=0.001
            :value="settings.factor.toatro"
            @input="settings.factor.toatro = $event.target.value"
            required
            validate
          ></f7-list-input>
          <f7-list-input
            v-if="!!settings.factor"
            label="to lutro"
            type="number"
            step=0.001
            :value="settings.factor.tolutro"
            @input="settings.factor.tolutro = parseFloat($event.target.value)"
            required
            validate
          ></f7-list-input>
          <f7-list-input
            v-if="!!settings.factor"
            label="ster"
            type="number"
            step=0.001
            :value="settings.factor.ster"
            @input="settings.factor.ster = parseFloat($event.target.value)"
            required
            validate
          ></f7-list-input>
        </f7-list>

        <hr/>
        <f7-block-title>Sortierung</f7-block-title>
        <f7-block-header>Sortierung der Liste</f7-block-header>
        <f7-list v-if="!!settings.sort">
          <f7-list-item value="id" :checked="settings.sort.liste == 'id'" @change="settings.sort.liste = 'id'" radio radio-icon="start" title="nach Erstellung" name="sortliste"></f7-list-item>
          <f7-list-item value="listnr" :checked="settings.sort.liste == 'number'" @change="settings.sort.liste = 'number'" radio radio-icon="start" title="nach Listennummer" name="sortliste"></f7-list-item>
        </f7-list>
        <f7-block-header>Sortierung der Polter</f7-block-header>
        <f7-list v-if="!!settings.sort">
          <f7-list-item value="id" :checked="settings.sort.polter == 'id'" @change="settings.sort.polter = 'id'" radio radio-icon="start" title="nach Erstellung" name="sortpolter"></f7-list-item>
          <f7-list-item value="polter" :checked="settings.sort.polter == 'polter'" @change="settings.sort.polter = 'polter'" radio radio-icon="start" title="nach Polternummer" name="sortpolter"></f7-list-item>
          <f7-list-item value="sortiment" :checked="settings.sort.polter == 'sortiment'" @change="settings.sort.polter = 'sortiment'" radio radio-icon="start" title="nach Sortiment" name="sortpolter"></f7-list-item>
        </f7-list>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import holzartendefault from '@/assets/holzarten.json'
import rabzugjson from '@/assets/rabzug.json'
import settingsdef from '@/assets/settings.json'
export default {
  data: function() {
    return {
      holzarten: [],
      denzin: ['BU', 'EI', 'ES', 'FI', 'KI', 'LÄ', 'TA', 'BI', 'ER', 'AH', 'LI', 'FÖ', 'LBH', 'NDH'],
      rabzug: {},
      settingsdefault: settingsdef,
      settings: {}
    }
  },
  mounted () {
    this.$f7ready(async (f7) => {
      if (localStorage.holzarten) {
        this.holzarten = JSON.parse(localStorage.holzarten)
      } else {
        this.holzarten = JSON.parse(JSON.stringify(holzartendefault));
      }
      this.rabzug = rabzugjson

      if (localStorage.settings) {
        this.settings = {...JSON.parse(localStorage.settings)}
        //this.settings = this.mergeDeep(this.settingsdefault, this.settings)
      } else {
        this.settings = Object.assign({}, this.settingsdefault)
      }
    })
  },
  watch: {
    holzarten: {
      deep: true,
      handler(newHolzarten) {
        localStorage.holzarten = JSON.stringify(newHolzarten);
      }
    },
    settings: {
      deep: true,
      handler(newSettings) {
        localStorage.settings = JSON.stringify(newSettings)
      }
    }
  },
  methods: {
    onSort(e) {
      this.arraymove(this.holzarten, e.from, e.to)
    },
    onDelete(e) {
      this.arrayremove(this.holzarten, e)
    },
    arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    },
    arrayremove(arr, index) {
      arr.splice(index, 1)
    },
    holzartreset() {
      this.holzarten = JSON.parse(JSON.stringify(holzartendefault));
      this.$forceUpdate
    },
    addholzart () {
      var self = this
      this.$f7.dialog.prompt('', 'Holzart', function (ha) {   
          console.log(self.holzarten.filter(o => {return o.holzart === ha}))
          if (self.holzarten.filter(o => {return o.holzart === ha}).length === 0) {
              self.holzarten.push({
                "holzart": ha,
                "liegend": false,
                "stehend": false
            })
          }
        }
      )
    },
  }
}
</script>