<template>
    <f7-page name="standingentry" infinite :infinite-preloader=false @infinite="loadMore">
        <f7-navbar title="Stehenderfassung" v-bind:subtitle="'Gruppe ' + stand.short_desc" back-link-force
            back-link-url="/standing/" back-link="Back">
            <div class="left">
                <f7-icon v-if="$root.bluetooth_connected && stand.tarif" material="bluetooth"></f7-icon>
                <button @click="$root.connect_bluetooth()" class="button"
                    v-if="!$root.bluetooth_connected && bluetooth_device && stand.tarif">
                    <f7-icon color="red" material="bluetooth_disabled"></f7-icon>
                </button>
            </div>
            <div class="right">
                <button @click="delEntry(activeentry)" class="button" v-if="activeentry > 0">
                    <f7-icon material="delete_outline"></f7-icon>
                </button>
            </div>
        </f7-navbar>
        <f7-toolbar class="keyboard" bottom>
            <f7-row class="keyboard__container">
                <f7-col class="keyboard__container-inner">
                    <!--Input rows-->
                    <f7-row class="keyboard__inputs" no-gap>
                        <f7-col>
                            <f7-input ref="baumart" outline inputStyle="height:40px;" type="text" placeholder="Baumart"
                                input_id="baumart" :value="standentry.baumart"
                                :class="{ 'highlight_small': 'baumart' == focuselmt }"
                                @input="standentry.baumart = $event.target.value"></f7-input>
                        </f7-col>
                        <f7-col v-if="!stand.tarif">
                            <f7-input ref="laenge" outline autofocus readonly inputStyle="height:40px" type="text"
                                placeholder="Baumhöhe" :value="standentry.laenge"
                                @input="standentry.laenge = $event.target.value"
                                :class="{ 'highlight_small': 'laenge' == focuselmt }" @blur="handleBlur('laenge')"
                                @focus="handleFocus('laenge')" clear-button></f7-input>
                        </f7-col>
                        <f7-col>
                            <f7-input ref="durchmesser" outline readonly inputStyle="height:40px" type="text"
                                placeholder="BHD" v-if="!kluppe" :value="standentry.durchmesser"
                                @input="standentry.durchmesser = $event.target.value"
                                :class="{ 'highlight_small': 'durchmesser' == focuselmt }"
                                @blur="handleBlur('durchmesser')" @focus="handleFocus('durchmesser')"></f7-input>
                            <f7-input ref="durchmesser" outline readonly inputStyle="height:40px" type="text"
                                placeholder="Stufe" v-if="kluppe" :value="kluppenstufe"
                                :class="{ 'highlight_small': 'durchmesser' == focuselmt }"
                                @blur="handleBlur('durchmesser')" @focus="handleFocus('durchmesser')"></f7-input>
                        </f7-col>
                        <f7-col>
                            <f7-input ref="tag" outline readonly inputStyle="height:40px" type="text" placeholder="Tag"
                                :value="standentry.tag" @input="standentry.tag = $event.target.value"
                                :class="{ 'highlight_small': 'tag' == focuselmt }" @blur="sheetOpened = true"
                                @focus="sheetOpened = true" @click="sheetOpened = true"></f7-input>
                        </f7-col>
                    </f7-row>

                    <!--Info Row-->
                    <f7-row class="keyboard__info">
                        <f7-col width='40'>
                            {{ comp_volume }} fm
                        </f7-col>
                        <f7-col width='60'>
                            <span v-if="kluppe">Kluppenstufe</span><span v-if="!kluppe">in cm</span>
                        </f7-col>
                    </f7-row>

                    <!--Keyboard-->
                    <KeyboardComp :kluppe.sync="kluppe" :baumarten="baumarten" :baumhöhe="baumhöhe" :tarif="stand.tarif"
                        @chgbaumart="standentry.baumart = $event" @chglaenge="standentry.laenge = $event"
                        @chgvalueks="standentry[focuselmt] = $event" @chgvalue="chg_value($event)"
                        @addcomment="addcomment()" @chgforward="chgforward()" @chgbackward="chgbackward()"
                        @saveentry="saveentry()" @chgremove="chg_remove()" />
                </f7-col>
            </f7-row>
        </f7-toolbar>
        <f7-row style="margin-bottom: 330px">
            <div
                style="margin-top: 5px; margin-bottom: -15px; text-align: center; width: 100%; background-color: greenyellow">
                Aktuelle Position: {{ lat }} / {{ long }}</div>
            <f7-col>
                <f7-block>
                    <f7-row no-gap>
                        <f7-col width="15">BA</f7-col>
                        <f7-col width="15" v-if="!this.stand.tarif">Höhe</f7-col>
                        <f7-col width="10">BHD</f7-col>
                        <f7-col width="10">KS</f7-col>
                        <f7-col width="15">FM</f7-col>
                        <f7-col width="35">Tag</f7-col>
                    </f7-row>
                    <template v-for="(stands, index) in standentries.slice(0, limit)">
                        <StandList :stands="stands" :key="stands.id" :activeentry="activeentry" :tarif="stand.tarif"
                            @editentry="editEntry($event)" />
                    </template>
                </f7-block>
            </f7-col>
        </f7-row>

        <StandTagSheet :sheetOpened.sync="sheetOpened" @tag="standentry.tag = $event" />
    </f7-page>
</template>
<script>
import KeyboardComp from '@/components/StandKeyboard.vue'
import StandList from '@/components/StandList.vue'
import StandTagSheet from '@/components/StandTagSheet.vue'
import tarife from '@/assets/tarife.json'
export default {
    tarife: tarife,
    components: {
        KeyboardComp, StandList, StandTagSheet
    },
    data: function () {
        return {
            activeentry: 0,
            focuselmt: null,
            stand_id: parseInt(this.$f7route.params.stand),
            standentry: {
                standing_id: parseInt(this.$f7route.params.stand),
                durchmesser: null,
                laenge: null,
                baumart: null,
                long: this.long,
                lat: this.lat,
                tag: null,
            },
            standentries: [],
            stand: {},
            limit: 25,
            baumartenDefault: ['BU', 'EI', 'ES', 'FI', 'KI', 'LÄ', 'TA', 'BI', 'ER'],
            baumartenSettings: JSON.parse(localStorage.holzarten || '[]'),
            baumhöhe: [5, 10, 15, 20, 25, 30, 35, 40],
            kluppe: false,
            tarife: {},
            long: 1,
            lat: 1,
            geoid: null,
            sheetOpened: false,
        }
    },
    mounted() {
        this.$f7ready(async (f7) => {
            console.log('bluetooth', this.$root.bluetooth)
            this.stand = await this.$dbnew.getStandingbyId(parseInt(this.stand_id))
            this.standentries = await this.$dbnew.getStandentriesbyStandingid(parseInt(this.stand_id))
            this.focuselmt = 'durchmesser'
            //this.get_location();
            this.trackPosition();
            this.$forceUpdate();

            if (JSON.parse(localStorage.settings || '{"stehendart": "cm"}').stehendart === 'kluppe') {
                this.kluppe = true
            }
            if (this.stand.tarif === true) {
                this.kluppe = true
                this.tarife = this.$options.tarife[this.stand.tarife]
            }

            if (this.standentries.length > 0) {
                this.standentry.baumart = this.standentries[0].baumart
            }
        })
    },
    beforeDestroy() {
        navigator.geolocation.clearWatch(this.geoid);
    },
    onPageBeforeOut() {
        const self = this;
        self.$f7.sheet.close();
        navigator.geolocation.clearWatch(this.geoid);
    },
    onPageBeforeRemove() {
        const self = this;
        if (self.sheet) self.sheet.destroy();
        navigator.geolocation.clearWatch(this.geoid);
    },
    watch: {
        async bluetooth_data(data) {
            if (data && data.substr(0, 2) === '$1' && data.includes("\n") === true && this.stand.tarif) {
                var line = data.split("\n")
                line = line.map(x => x.split(";"))
                line = line.map(x => x.map(e => typeof e === 'string' ? e.trim() : typeof e))
                line.forEach(async (l) => {
                    if (l.length > 1) {
                        this.standentry.baumart = l[7].toUpperCase();
                        this.standentry.durchmesser = parseFloat(l[10]);
                        this.standentry.fm = this.tarife[this.kluppenstufe]
                        await this.saveentry()
                    }
                })
                this.bluetooth_data = ''
            } else if (data && data.substr(0, 2) === '$1' && data.includes("\n") && this.stand.tarif) {
                this.$f7.dialog.alert("Die Aufnahme funktioniert nur mit Tarif")
                this.bluetooth_data = ''
            } else if (data && data.substr(0, 2) != '$1' && data.includes("\n")) {
                this.$f7.dialog.alert("Falsche Daten - Bist du in der Liegenderfassung?")
            }
        }
    },
    computed: {
        baumarten: function () {
            if (this.baumartenSettings.length === 0) {
                return this.baumartenDefault
            } else {
                return this.baumartenSettings
                    .filter(ha => ha.stehend === true)
                    .reduce(function (acc, cur) {
                        acc.push(cur.holzart);
                        return acc;
                    }, [])
            }
        },
        comp_volume: function () {
            var fm = 0;
            if (!this.tarif && this.standentry.durchmesser != null && this.standentry.laenge != null && this.standentry.baumart) {
                var durchmesser = parseFloat(this.standentry.durchmesser)
                var laenge = parseFloat(this.standentry.laenge)
                var nm = 0; // Normalhöhe
                var vkp = 0; // Volumenkorrekturprozent

                switch (this.standentry.baumart) {
                    case 'FI':
                        nm = 19 + (2 * durchmesser / 10);
                        vkp = 4;
                        break
                    case 'TA':
                        nm = 21 + durchmesser / 10;
                        vkp = 4;
                        break;
                    case 'NDH':
                        nm = 21 + durchmesser / 10;
                        vkp = 4;
                        break;
                    case 'LÄ':
                        nm = 17 + (3 * durchmesser / 10);
                        vkp = 5;
                        break;
                    case 'FÖ':
                        nm = 17 + (3 * durchmesser / 10);
                        vkp = 5;
                        break;
                    case 'KI':
                        nm = 28
                        vkp = 3
                        break;
                    case 'BU':
                        nm = 25
                        vkp = 3
                        break;
                    case 'LBH':
                        nm = 25
                        vkp = 3
                        break;
                    case 'AH':
                        nm = 25
                        vkp = 3
                        break;
                    case 'LI':
                        nm = 25
                        vkp = 3
                        break;
                    case 'EI':
                        nm = 24
                        vkp = 3
                        break;
                    case 'BI':
                        nm = 31
                        vkp = 3
                        break;
                    case 'ER':
                        nm = 31
                        vkp = 3
                        break;
                }
                fm = this.round((Math.pow(durchmesser, 2) / 1000) + (Math.pow(durchmesser, 2) / 1000) * (laenge - nm) * (vkp / 100), 2)
                this.standentry.fm = fm
            } else if (this.stand.tarif >= 0 && this.standentry.durchmesser != null) {
                fm = this.tarife[this.kluppenstufe]
                this.standentry.fm = fm
            } else if (this.stand.tarif) {
                fm = this.tarife[this.kluppenstufe]
                this.standentry.durchmesser = 1
                this.standentry.fm = fm
            } else {
                fm = 0
            }
            return fm
        },
        kluppenstufe: function () {
            return this.calc_kluppenstufe(this.standentry.durchmesser)
        },
        bluetooth_data: {
            get() {
                return this.$root.bluetooth_data
            },
            set() {
                this.$root.bluetooth_data = ""
            }
        },
        bluetooth_device: function () {
            return localStorage.bluetooth || null
        },
    },
    methods: {
        round(x, n) {
            var a = Math.pow(10, n)
            return (Math.round(x * a) / a)
        },
        calc_kluppenstufe(dm) {
            return Math.max(Math.ceil(((dm || 0) - 15) / 4), 0)
        },
        // -------------- LOCATION TRACKING
        trackPosition() {
            if (navigator.geolocation) {
                this.geoid = navigator.geolocation.watchPosition(this.successPosition, this.failurePosition, {
                    enableHighAccuracy: true,
                    timeout: 15000,
                    maximumAge: 0,
                })
            } else {
                this.$f7.dialog.alert(`Browser doesn't support Geolocation`)
            }
        },
        successPosition: function (pos) {
            this.long = pos.coords.longitude
            this.lat = pos.coords.latitude
        },
        failurePosition: function (err) {
            this.$f7.dialog.alert("Position konnte nicht ermittelt werden.")
            this.$f7.dialog.alert('Error Code: ' + err.code + ' Error Message: ' + err.message)
        },

        get_location() {
            var options = {
                enableHighAccuracy: true,
                timeout: 15000,
                maximumAge: 0
            };
            if (!('geolocation' in navigator)) {
                this.errorStr = 'Geolocation is not available.'
                this.$f7.dialog.alert(this.errorStr)
                return
            }

            this.gettingLocation = true

            navigator.geolocation.getCurrentPosition(pos => {
                this.gettingLocation = false
                this.long = pos.coords.longitude
                this.lat = pos.coords.latitude
            }, err => {
                this.gettingLocation = false
                this.errorStr = err.message
            }, options)
        },

        // -------------- /LOCATION TRACKING

        loadMore() {
            this.limit = this.limit + 50
        },

        async saveentry() {
            delete this.standentry.id

            if (this.standentry.baumart === null || this.standentry.baumart === '') {
                this.$f7.dialog.alert('Bitte Baumart eingeben')
                return
            }

            if (this.activeentry === 0) {
                this.standentry.long = this.long
                this.standentry.lat = this.lat
                await this.$dbnew.addStandingentry(this.standentry)
            } else {
                var data = await this.$dbnew.updateStandingentrybyId(parseInt(this.activeentry), this.standentry)
                this.activeentry = 0
            }

            this.focuselmt = 'durchmesser'
            this.standentry.durchmesser = null
            this.standentry.fm = 0
            this.standentry.comment = null
            this.standentry.long = null
            this.standentry.lat = null

            this.standentries = await this.$dbnew.getStandentriesbyStandingid(parseInt(this.stand_id))
        },

        async delEntry(entryid) {
            this.$dbnew.delStandingentrybyId(parseInt(entryid))
            this.focuselmt = 'durchmesser'
            this.standentry.durchmesser = null
            this.standentry.fm = 0
            this.activeentry = 0
            this.standentry.long = null
            this.standentry.lat = null

            this.standentries = await this.$dbnew.getStandentriesbyStandingid(parseInt(this.stand_id))
        },

        editEntry(sentry) {
            if (this.activeentry === parseInt(sentry.id) && this.activeentry > 0) {
                this.activeentry = 0
                this.focuselmt = 'durchmesser'
                this.standentry.durchmesser = null
                this.standentry.fm = 0
                this.standentry.long = null
                this.standentry.lat = null
            } else {
                this.activeentry = parseInt(sentry.id)
                this.standentry = { ...sentry }
            }
        },

        addcomment() {
            var self = this
            this.$f7.dialog.prompt('', 'Baumkommentar', function (comment) {
                if (comment == '') {
                    self.standentry.comment = null
                } else {
                    self.standentry.comment = comment
                }
            }, null, this.standentry.comment)
        },
        chg_value(wert) {
            if (this.standentry[this.focuselmt] == null) {
                this.standentry[this.focuselmt] = ''
            }
            this.standentry[this.focuselmt] = this.standentry[this.focuselmt] + wert
            this.$refs[this.focuselmt].$refs.inputEl.focus()
        },
        chg_remove(ev) {
            if (this.standentry[this.focuselmt] == null) {
                this.standentry[this.focuselmt] = ''
            }
            this.standentry[this.focuselmt] = this.standentry[this.focuselmt].toString().slice(0, -1)
            this.$refs[this.focuselmt].$refs.inputEl.focus()
        },
        chgforward() {
            switch (this.focuselmt) {
                case 'laenge':
                    this.focuselmt = 'durchmesser'
                    break
                case 'durchmesser':
                    this.focuselmt = 'laenge'
                    break
            }
        },
        chgbackward() {
            switch (this.focuselmt) {
                case 'laenge':
                    this.focuselmt = 'durchmesser'
                    break
                case 'durchmesser':
                    this.focuselmt = 'laenge'
                    break
            }
        },
        handleBlur(e) {
            this.focuselmt = e
        },
        handleFocus(e) {
            this.focuselmt = e
            document.activeElement.blur()
        },
    }
}
</script>
<style lang="less">
.keyboard {
    &__keyboard {

        &-treeheight {
            width: 30% !important;

            >.row {
                height: 50%;
            }
        }

    }
}
</style>