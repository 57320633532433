import { render, staticRenderFns } from "./objectdetection.vue?vue&type=template&id=5872d76e&scoped=true"
import script from "./objectdetection.vue?vue&type=script&lang=js"
export * from "./objectdetection.vue?vue&type=script&lang=js"
import style0 from "./objectdetection.vue?vue&type=style&index=0&id=5872d76e&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5872d76e",
  null
  
)

export default component.exports