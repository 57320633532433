<template>
    <f7-page name="Standing">
    <f7-navbar title="Stehenderfassung" back-link="Back" back-link-force back-link-url="/lists/" subtitle="Erfassung von stehenden Bäumen"></f7-navbar>
    <f7-block-title>Gruppe</f7-block-title>
    <f7-block>
        <p>Eine Gruppe fasst die Einträge/Stämme zusammen. Dies kann zum Beispiel ein Holzschlag sein.</p>
    </f7-block>
    <f7-list media-list style="padding-bottom: 5rem">
      <f7-list-item
        v-for="stand in standing"
        :key="stand.id"
        :title="stand.short_desc"
        :text="stand.comment"
        :badge="showBadge(stand)"
        :link="`/standingentry/${stand.id}/`"
        swipeout
        @swipeout:deleted="onDeleted(stand.id)"
      >
      <div slot="subtitle">
        <template v-for="t in showSubtitle(stand)">
          <f7-badge >{{ t }}</f7-badge> &nbsp;
        </template>
      </div>
       <f7-swipeout-actions left>
          <f7-swipeout-button
            delete
            confirm-text="Möchtest du wirklich die Gruppe und alle dazugehörigen Stämme löschen?"
            color="red"
          >
            <f7-icon material="delete_outline"></f7-icon>
          </f7-swipeout-button>
        </f7-swipeout-actions>
        <f7-swipeout-actions right>
          <f7-swipeout-button @click="editStand(stand.id)">
            <f7-icon material="create"></f7-icon>
          </f7-swipeout-button>
        </f7-swipeout-actions>
      </f7-list-item>
    </f7-list>
    <f7-fab
      position="center-bottom"
      slot="fixed"
      text="Neue Gruppe erstellen"
      href="/standingform/"
    >
      <f7-icon material="add"></f7-icon>
    </f7-fab>
  </f7-page>
</template>
<script>
export default {
    data: function () {
        return {
            standing: null,
            stats: [],
        };
    },
    methods: {
        showBadge(stand) {
            var stats = this.stats[parseInt(stand.id)];
            if (stats !== undefined) {
                stats = this.stats[parseInt(stand.id)];
                return parseInt(stats["fm"] || 0) + " fm \t/ " + (stats["cnt"] || 0) + " Stück";
            }
        },
        showSubtitle(stand) {
            var stats = this.stats[parseInt(stand.id)];
            if (stats !== undefined && stats["types"]) {
                var types = [];
                for (var type in stats["types"]) {
                    types.push(type + " " + parseInt(stats["types"][type]) + " fm");
                }
                return types
            }
        },
        async onDeleted(standid) {
            const app = this.$f7;
            await this.$dbnew.delStandingbyId(standid);
            app.dialog.alert("Gruppe erfolgreich gelöscht");
        },
        editStand(standid) {
            this.$f7.views.current.router.navigate("/standingform/" + standid + "/");
        }
    },
    mounted() {
        this.$f7ready(async (f7) => {
            // Stehenderfassung Gruppe
            this.standing = await this.$dbnew.getStanding();
            this.stats = await this.$dbnew.getSumEntriesPerStanding();
        });
    },
}
</script>
<style scoped>

</style>