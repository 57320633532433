import Dexie from 'dexie'
var db = new Dexie('mobipolter')
import holzarten from "@/assets/holzarten.json";

// ----------------- DB Definition -----------------
db.version(1).stores(
    {
      lists: '++id, number, comment',
      polters: '++id, list_id, polter, sortiment, comment',
      logs: '++id, polter_id, baumart, plaettchen'
    }
  )
  db.version(2).stores().upgrade(tx => {
    tx.table('polters').toCollection().modify(polter => {
      polter.list_id = parseInt(polter.list_id) // Id als Int
    })
    tx.table('logs').toCollection().modify(logs => {
      logs.polter_id = parseInt(logs.polter_id)  // Id als Int
    })
  })
  
  db.version(3).stores(
    {
      sections: '++id, polter_id, typ'
    }
  ).upgrade(tx => {
    tx.table('polters').toCollection().modify(polter => {
      polter.erfassungsmethode = 'log' // Erfassungsmethode mit 'Log' ergänzen, falls diese Null ist
    })
  })
  
  db.version(4).stores(
    {
      images: '++id, polter_id, dataurl, size',
      standing: '++id',
      standing_entries: '++id, standing_id'
    }
  )

// ----------------- /DB Definition -----------------
  

var dbnew = {
    db: db,

    getStats: async function () {
      return {
        lists: await this.db.lists.count(),
        polters: await this.db.polters.count(),
        logs: await this.db.logs.count(),
        sections: await this.db.sections.count(),
        images: await this.db.images.count(),
        standing: await this.db.standing.count(),
        standing_entries: await this.db.standing_entries.count(),
      }
    },

    getData: async function () {
      return {
        lists: await this.db.lists.toArray(),
        polters: await this.db.polters.toArray(),
        logs: await this.db.logs.toArray(),
        sections: await this.db.sections.toArray(),
        standing: await this.db.standing.toArray(),
        standing_entries: await this.db.standing_entries.toArray(),
      }
    },

    // LIST
    getLists: async function (sort = 'id') {
        if (sort === 'id') {
          return await this.db.lists.toArray()
        } else {
          return await this.db.lists.orderBy(sort).toArray()
        }
    },
    getListbyId: async function (id) {
        return await this.db.lists.get(parseInt(id))
    },
    updateListbyId: async function(id, data) {
        await this.db.lists.update(parseInt(id), data)
    },
    addList: async function(data) {
        return await this.db.lists.put(data)
    },
    delListbyId: async function(id) {
        var polters = await this.db.polters.where('list_id').equals(id).primaryKeys()
        await this.db.logs.where('polter_id').anyOf(polters).delete() // Stämme
        await this.db.sections.where('polter_id').anyOf(polters).delete() // Sections
        await this.db.images.where('polter_id').anyOf(polters).delete() // Images
        await this.db.polters.where('list_id').equals(id).delete() // Polter
        await this.db.lists.delete(parseInt(id)) // Liste
    },
    getPolterPerLists: async function() {
        var result = {}
        await this.db.polters.each(function (polter, i) {
            result[polter.list_id] = (result[polter.list_id] || 0) +1
        })
        return result
    },

    // POLTERS
    getPolters: async function () {
      return await this.db.polters.toArray()
    },
  getPoltersbyListid: async function (id, sort = 'id') {
      if (sort === 'id') {
        return await this.db.polters.where('list_id').equals(parseInt(id)).toArray()
      } else {
        return await this.db.polters.where('list_id').equals(parseInt(id)).sortBy(sort)
      }
    },
    getPolterbyId: async function (id) {
        return await this.db.polters.get(parseInt(id))
    },
    addPolter: async function(data) {
        return await this.db.polters.put(data)
    },
    updatePolterbyId: async function(id, data) {
        await this.db.polters.update(parseInt(id), data)
    },
    delPolterbyId: async function (id) {
        await this.db.logs.where('polter_id').equals(id).delete() // Stämme
        await this.db.sections.where('polter_id').equals(id).delete() // Sections
        await this.db.images.where('polter_id').equals(id).delete() // Imagegs
        await this.db.polters.delete(parseInt(id)) // Polter
    },
    getSumEntriesPerList: async function (id) {
        var result = {}
        await this.db.logs.each(function(log, i) {
            result[log.polter_id] = result[log.polter_id] || {}
            result[log.polter_id]['cnt'] = (result[log.polter_id]['cnt'] || 0) +1
            result[log.polter_id]['fm'] = (result[log.polter_id]['fm'] || 0) + parseFloat(log.fm)
        })

        await this.db.sections.where({ typ: 'sum' }).each(function(section, i) {
            result[section.polter_id] = {fm:parseFloat(section.fm), rm: parseFloat(section.rm)}
        })

        await this.db.images.each(function(img, i){
            result[img.polter_id] = result[img.polter_id] || {}
            result[img.polter_id]['img'] = (result[img.polter_id]['img'] || 0) +1
        })
        return result
    },

    // LOGS
    getLogsbyPolterid: async function(id) {
      return await this.db.logs.where('polter_id').equals(parseInt(id)).reverse().sortBy('id')
    },
    delLogbyId: async function (id) {
      return await this.db.logs.delete(id)
    },
    addLog: async function(data) {
      return await this.db.logs.put(data)
    },
    updateLogbyId: async function(id, data) {
      await this.db.logs.update(parseInt(id), data)
    },

    // SECTIONS
    getSectionsbyPolterid: async function(id) {
      return await this.db.sections.where({ polter_id: parseInt(id), typ: 'section' }).reverse().sortBy('id')
    },
    delSectionbyId: async function(id) {
      return await this.db.sections.delete(parseInt(id))
    },
    getSumSectionbyPolterid: async function (id) {
      return await this.db.sections.where({ polter_id: parseInt(id), typ: 'sum' }).first();
    },
    getSectionsbyPolteridforExport: async function(id) {
      return await this.db.sections.where({ polter_id: parseInt(id) }).toArray()
    },
    addSection: async function(data) {
      return await this.db.sections.put(data)
    },
    updateSectionbyId: async function(id, data) {
      await this.db.sections.update(parseInt(id), data)
    },

    // Standing
    getStanding: async function() {
      return await this.db.standing.toArray()
    },
    getStandingbyId: async function (id) {
      return await this.db.standing.get(parseInt(id))
    },
    updateStandingbyId: async function(id, data) {
      await this.db.standing.update(parseInt(id), data)
    },
    addStanding: async function(data) {
        return await this.db.standing.put(data)
    },
    getSumEntriesPerStanding: async function () {
      var result = {}
      await this.db.standing_entries.each(function(stand, i) {
          result[stand.standing_id] = result[stand.standing_id] || {}
          result[stand.standing_id]["types"] = result[stand.standing_id]["types"] || {};
          result[stand.standing_id]['cnt'] = (result[stand.standing_id]['cnt'] || 0) +1
          result[stand.standing_id]['fm'] = (result[stand.standing_id]['fm'] || 0) + parseFloat(stand.fm)
        var type = holzarten.filter((ha) => ha.holzart === stand.baumart);
        type = type.length > 0 ? type[0].holzart_typ : "unbekannt";
          result[stand.standing_id]["types"][type] =
            (result[stand.standing_id]["types"][type] || 0) +
            parseFloat(stand.fm);
      })

      return result
    },
    delStandingbyId: async function(id) {
      await this.db.standing.delete(parseInt(id)) // Standing
      await this.db.standing_entries.where('standing_id').equals(id).delete() // Entries
    },
    getStandingforExport: async function () {
      var data = await this.getStanding()
      var formatlist = {}
      data.forEach((data) => {
        formatlist[data.id] = data
        formatlist[data.id].selected = false
      })
      return formatlist
    },

    //Entries
    getStandentriesbyStandingid: async function(id) {
      return await this.db.standing_entries.where('standing_id').equals(parseInt(id)).reverse().sortBy('id')
    },
    addStandingentry: async function(data) {
      return await this.db.standing_entries.put(data)
    },
    updateStandingentrybyId: async function(id, data) {
      await this.db.standing_entries.update(parseInt(id), data)
    },
    delStandingentrybyId: async function(id) {
      await this.db.standing_entries.delete(parseInt(id))
    },

    // IMAGES
    getImagesbyPolterid: async function (id) {
        return await this.db.images.where('polter_id').equals(parseInt(id)).toArray()
    },
    getImagebyId: async function(id) {
      return await this.db.images.get(parseInt(id))
    },
    updateImagebyId: async function(id, data) {
      await this.db.images.update(parseInt(id), data)
    },
    delImagebyId: async function (id) {
        return await this.db.images.delete(id)
    },
    addImage: async function(data) {
        return this.db.images.put(data)
    },

    // EXPORT
    getListsPoltersforExport: async function (id) {
      var data = await this.getLists()
      var formatlist = {}

      data.forEach((data) => {
        formatlist[data.id] = data
        formatlist[data.id].polter = {}
        formatlist[data.id].selected = {}
      })

      var datap = await this.getPolters() 
      datap.forEach((data) => {
        formatlist[data.list_id].polter[data.id] = data
        formatlist[data.list_id].selected[data.id] = false
      })

      return formatlist
    }
  };

  export default dbnew;