import Vue from "vue";

import Framework7 from "framework7/framework7-lite.esm.bundle.js";
import Framework7Vue from "framework7-vue/framework7-vue.esm.bundle.js";

import "framework7/css/framework7.bundle.css";

import "../css/icons.css";
import "../css/app.less";

import App from "../components/app.vue";
import axios from "axios";

import { v4 as uuidv4 } from "uuid";
import device from "vue-device-detector";

import dbnew from "@/js/db.js";
Vue.prototype.$dbnew = dbnew;

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import settingsdef from "@/assets/settings.json";
import holzartendef from "@/assets/holzarten.json";

Sentry.init({
  Vue,
  dsn: "https://06ff779279f9477a86709e5ab7e637ee@o541819.ingest.sentry.io/5661787",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  logErrors: true,
  environment: process.env.NODE_ENV,
});

/*var db = new Dexie('mobipolter')
db.version(1).stores(
  {
    lists: '++id, number, comment',
    polters: '++id, list_id, polter, sortiment, comment',
    logs: '++id, polter_id, baumart, plaettchen'
  }
)
db.version(2).stores().upgrade(tx => {
  tx.table('polters').toCollection().modify(polter => {
    polter.list_id = parseInt(polter.list_id) // Id als Int
  })
  tx.table('logs').toCollection().modify(logs => {
    logs.polter_id = parseInt(logs.polter_id)  // Id als Int
  })
})

db.version(3).stores(
  {
    sections: '++id, polter_id, typ'
  }
).upgrade(tx => {
  tx.table('polters').toCollection().modify(polter => {
    polter.erfassungsmethode = 'log' // Erfassungsmethode mit 'Log' ergänzen, falls diese Null ist
  })
})

db.version(4).stores(
  {
    images: '++id, polter_id, dataurl, size',
    standing: '++id',
    standing_entries: '++id, standing_id'
  }
)

Vue.prototype.$db = db
*/
// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);
Vue.use(device);

Vue.prototype.$http = axios;

// Init App
new Vue({
  axios,
  el: "#app",
  render: (h) => h(App),
  components: {
    app: App,
  },
  data: function () {
    return {
      //url: 'https://mobipolter.szsolutions.ch',
      url: process.env.VUE_APP_API_URL,
      bluetooth: {},
      bluetooth_data: "",
      bluetooth_tmp: "",
      bluetooth_connected: false,
      message: "",
      wakeLockObj: null,
    };
  },

  created() {
    window.addEventListener(
      "beforeunload",
      () => {
        this.sendstats();
      },
      false
    );
  },

  methods: {
    async sendstats() {
      // Set UUID and send stats to server
      var uuid = localStorage.getItem("uuid");
      if (!uuid) {
        // create new uuid
        uuid = uuidv4();
        localStorage.setItem("uuid", uuid);
      }

      var stats = await dbnew.getStats();
      var data = await dbnew.getData();

      var stats = {
        uuid: uuid,
        log_date: Date.now(),
        log_data: JSON.stringify(stats),
        log_full_data: JSON.stringify(data),
        log_user: localStorage.getItem("user") || "",
      };

      this.$http({
        url: this.url + "/stats",
        data: stats,
        method: "POST",
      }).catch((err) => {
        console.warn("Error sending Stats", err);
      });
    },
    async checkpolters() {
      // Check Polters in the localstorage and fill up to 20
      var polters = JSON.parse(localStorage.polters || "[]");

      if (polters.length < 10 && (localStorage.user_id || localStorage.user)) {
        await this.$http({
          url: this.url + "/localpolter_get",
          data: { number: 10 - polters.length },
          method: "POST",
          withCredentials: true,
        })
          .then((data) => {
            polters = localStorage.polters = JSON.stringify(
              polters.concat(data.data.polters)
            );
          })
          .catch((err) => {
            console.warn("Error catching Polters", err);
          });
      }
    },
    async sendobjectdetection() {
      const images = await this.$dbnew.getImagesbyPolterid(0);

      images.forEach((i) => {
        if (!i.sent && i.preds) {
          this.$http({
            url: this.url + "/mobipolter_ob",
            data: i,
            method: "POST",
            withCredentials: true,
          })
            .then((data) => {
              i.sent = true;
              this.$dbnew.updateImagebyId(parseInt(i.id), i);
            })
            .catch((err) => {
              console.warn("Updload doesnt work");
            });
        }
      });
    },

    async loadsettings() {
      console.log(settingsdef);
      if (!localStorage.settings) {
        localStorage.settings = JSON.stringify(settingsdef);
      } else {
        var settings = { ...JSON.parse(localStorage.settings) };
        console.log(settings);
        settings = this.mergeDeep(settingsdef, settings);
        console.log(settings);
        localStorage.settings = JSON.stringify(settings);
      }
      if (!localStorage.holzarten) {
        localStorage.holzarten = JSON.stringify(holzartendef);
      }
    },

    mergeDeep(target, ...sources) {
      if (!sources.length) return target;
      const source = sources.shift();

      if (this.isObject(target) && this.isObject(source)) {
        for (const key in source) {
          if (this.isObject(source[key])) {
            if (!target[key]) Object.assign(target, { [key]: {} });
            this.mergeDeep(target[key], source[key]);
          } else {
            Object.assign(target, { [key]: source[key] });
          }
        }
      }

      return this.mergeDeep(target, ...sources);
    },
    isObject(item) {
      return item && typeof item === "object" && !Array.isArray(item);
    },

    connect_bluetooth() {
      let options = {
        filters: [{ namePrefix: "MD" }],
        optionalServices: ["6e400001-b5a3-f393-e0a9-e50e24dcca9e"],
      };

      if (navigator.bluetooth) {
        navigator.bluetooth
          .requestDevice(options)
          .then((device) => {
            this.bluetooth = device;
            this.message = "Verbinde...";
            return device.gatt.connect();
          })
          .then((server) => {
            //this.bluetooth = device
            this.bluetooth.addEventListener(
              "gattserverdisconnected",
              this.handleDisconnected
            );
            this.message = "Verbinde...";
            return server.getPrimaryService(
              "6e400001-b5a3-f393-e0a9-e50e24dcca9e"
            );
          })
          .then((service) => {
            this.message = "Wähle Service...";
            localStorage.setItem("bluetooth", this.bluetooth.name);
            return service.getCharacteristic(
              "6e400003-b5a3-f393-e0a9-e50e24dcca9e"
            );
          })
          .then((characteristic) => {
            let myCharacteristic = characteristic;
            this.message = "Starte Notifikation...";
            this.message = "Alles klar!";
            this.bluetooth_connected = true;
            return myCharacteristic.startNotifications().then((_) => {
              myCharacteristic.addEventListener(
                "characteristicvaluechanged",
                this.handleNotifications
              );
            });
          });
      } else {
        this.message = "Dein Browser oder Gerät unterstützt kein Bluetooth";
      }
    },

    disconnect_bluetooth() {
      if (!this.bluetooth) {
        return;
      }
      this.message = "Trenne Verbindung";
      if (this.bluetooth.gatt.connected) {
        this.$nextTick(function () {
          this.bluetooth.gatt.disconnect();
          this.bluetooth = {
            name: "def",
            id: "",
            gatt: { connected: false },
          };
          this.message = "Verbindung getrennt";
          this.bluetooth_connected = false;
        });
      } else {
        this.message = "Verbindung bereits getrennt";
      }
    },
    handleNotifications(event) {
      let utf8decoder = new TextDecoder("utf-8");
      let value = event.target.value;
      this.$root.bluetooth_tmp = this.$root.bluetooth_tmp.concat(
        utf8decoder.decode(value)
      );
      if (
        this.$root.bluetooth_tmp &&
        this.$root.bluetooth_tmp.substr(0, 1) === "$" &&
        this.$root.bluetooth_tmp.includes("\n") === true
      ) {
        const options = {
          body: "",
          tag: "ls",
          renotify: true,
          icon: "static/icons/192x192.png",
          vibrate: [200, 100, 200],
        };

        navigator.serviceWorker
          .getRegistration()
          .then((reg) =>
            reg.showNotification("Bluetooth Daten erhalten", options)
          )
          .catch((err) => console.log("no notification possible", err));

        this.$root.bluetooth_data += this.$root.bluetooth_tmp;
        this.$root.bluetooth_tmp = "";
      }
    },

    handleDisconnected(event) {
      this.message = "Verbindung getrennt";
      this.bluetooth_connected = false;
      //this.reconnect();
    },

    reconnect() {
      var that = this;
      this.exponentialBackoff(
        3 /* max retries */,
        2 /* seconds delay */,
        function toTry() {
          that.time("Connecting to Bluetooth Device... ");
          that.message = "Verbinde...";
          return that.bluetooth.gatt.connect();
        },
        function success() {
          console.log("> Bluetooth Device connected. Try disconnect it now.");
          that.message = "Alles klar!";
          that.bluetooth_connected = true;
        },
        function fail() {
          that.time("Failed to reconnect.");
          that.message = "Verbindung getrennt";
          that.bluetooth_connected = false;
        }
      );
    },
    exponentialBackoff(max, delay, toTry, success, fail) {
      var that = this;
      toTry()
        .then((result) => success(result))
        .catch((_) => {
          if (max === 0) {
            return fail();
          }
          this.time("Retrying in " + delay + "s... (" + max + " tries left)");
          setTimeout(function () {
            that.exponentialBackoff(--max, delay * 2, toTry, success, fail);
          }, delay * 1000);
        });
    },
    time(text) {
      console.log("[" + new Date().toJSON().substr(11, 8) + "] " + text);
    },
  },

  async mounted() {
    var that = this;
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then(async function (reg) {
        reg.addEventListener("updatefound", () => {
          console.log("Update found. Waiting for install to complete.");
          const installingWorker = reg.installing;
          installingWorker.addEventListener("statechange", () => {
            if (installingWorker.state === "installed") {
              console.log("Install complete. Triggering update prompt.");
              that.$f7.dialog.confirm(
                "Eine neue Version ist verfügbar. Soll diese aktualisiert werden?",
                function () {
                  window.location.reload();
                }
              );
            }
          });
        });
      });
    }

    this.loadsettings();
    this.sendstats();
    this.checkpolters();
    //this.sendobjectdetection();
  },
});
