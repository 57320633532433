<template>
<f7-page name="logform" infinite :infinite-preloader=false @infinite="loadMore">
    <f7-navbar v-bind:title="'Stämme: Polter ' + polter.polter" v-bind:subtitle="(polter.sortiment || '') + ' ' + (polter.waldort || '')" back-link-force v-bind:back-link-url="'/list/' + polter.list_id + '/'" back-link="Back">
          <div class="left">
              <f7-icon v-if="$root.bluetooth_connected" material="bluetooth"></f7-icon>
              <button @click="$root.connect_bluetooth()" class="button" v-if="!$root.bluetooth_connected && bluetooth_device" >
                <f7-icon color="red"  material="bluetooth_disabled"></f7-icon>
              </button>
          </div>
          <div class="right">
              <button @click="searchlog()" class="button" >
                  <f7-icon material="search"></f7-icon>
              </button>
              <button @click="delLog(activelog)" class="button" v-if='activelog > 0' >
                  <f7-icon material="delete_outline"></f7-icon>
              </button>
          </div>
    </f7-navbar>
    <f7-toolbar class="keyboard" bottom>
      <f7-row class="keyboard__container">
        <f7-col class="keyboard__container-inner">
            <!--Input rows-->
            <f7-row class="keyboard__inputs" no-gap>
               <f7-col>
                    <f7-input
                        ref="baumart"
                        outline
                        inputStyle="height:40px;"
                        type="text"
                        placeholder="Baumart"
                        input_id="baumart"
                        :value="log.baumart"
                        :class="{ 'highlight_small' : 'baumart' == focuselmt}"
                        @input="log.baumart = $event.target.value"
                      ></f7-input>
                </f7-col>
                <f7-col>
                    <f7-input
                        ref="stammnr"
                        outline
                        inputStyle="height:40px"
                        type="text"
                        readonly
                        placeholder="Stammnr"
                        :value="log.stammnr"
                        @input="log.stammnr = $event.target.value"
                        :class="{ 'highlight_small' : 'stammnr' == focuselmt}"
                        @blur="handleBlur('stammnr')"
                        @focus="handleFocus('stammnr')"
                      ></f7-input>
                </f7-col>
                <f7-col>
                    <f7-input
                        ref="laenge"
                        outline
                        autofocus
                        readonly
                        inputStyle="height:40px"
                        type="text"
                        placeholder="Länge"
                        :value="log.laenge"
                        @input="log.laenge = $event.target.value"
                        :class="{ 'highlight_small' : 'laenge' == focuselmt}"
                        @blur="handleBlur('laenge')"
                        @focus="handleFocus('laenge')"
                        clear-button
                      ></f7-input>
                </f7-col>
                <f7-col>
                    <f7-input
                        ref="durchmesser"
                        outline
                        readonly
                        inputStyle="height:40px"
                        type="text"
                        placeholder="Durchmesser"
                        :value="log.durchmesser"
                        @input="log.durchmesser = $event.target.value"
                        :class="{ 'highlight_small' : 'durchmesser' == focuselmt}"
                        @blur="handleBlur('durchmesser')"
                        @focus="handleFocus('durchmesser')"
                        clear-button
                      ></f7-input>
                </f7-col>
                <f7-col>
                    <f7-input
                        ref="qualitaet"
                        outline
                        inputStyle="height:40px"
                        type="text"
                        placeholder="Qualität"
                        :value="log.qualitaet"
                        @input="log.qualitaet = $event.target.value"
                      ></f7-input>

                </f7-col>
            </f7-row>

            <!--Info Row-->
            <f7-row class="keyboard__info">
              <f7-col width='40'>
                {{ comp_volume }} fm / {{ comp_staerke }} / {{ comp_rabzug }}
              </f7-col>
              <f7-col width='60'>
                {{ comp_totale }}
              </f7-col>
            </f7-row>

            <!--Keyboard-->
            <f7-row class="keyboard__keyboard">
              <f7-col class="keyboard__keyboard-tree">
                <f7-row no-gap>
                  <f7-col class="button-column" width="100" v-for="baum in baumarten" :key="baum">
                    <button class="col-button" @click="click_feedback($event); chg_baumart(baum, this)"><span class="nichtmarkierbar">{{ baum }}</span></button>
                  </f7-col>
                </f7-row>
              </f7-col>
              <f7-col class="keyboard__keyboard-quality">
                <f7-row no-gap>
                  <f7-col class="button-column" width="50" v-for="qual in qualitaeten" :key="qual">
                    <button class="col-button" @click="click_feedback($event); chg_qualitaet(qual)"><span class="nichtmarkierbar">{{ qual }}</span></button>
                  </f7-col>
                </f7-row>
              </f7-col>
              <f7-col class="keyboard__keyboard-numblock">
                <f7-row no-gap>
                  <f7-col class="button-column" width="33">
                    <button class="col-button col-button--big" @click="click_feedback($event); chg_value('7')"><span class="nichtmarkierbar">7</span></button>
                  </f7-col>
                  <f7-col class="button-column" width="33">
                    <button class="col-button col-button--big" @click="click_feedback($event); chg_value('8')"><span class="nichtmarkierbar">8</span></button>
                  </f7-col>
                  <f7-col class="button-column" width="33">
                    <button class="col-button col-button--big" @click="click_feedback($event); chg_value('9')"><span class="nichtmarkierbar">9</span></button>
                  </f7-col>
                </f7-row>
                <f7-row no-gap>
                  <f7-col class="button-column" width="33">
                    <button class="col-button col-button--big" @click="click_feedback($event); chg_value('4')"><span class="nichtmarkierbar">4</span></button>
                  </f7-col>
                  <f7-col class="button-column" width="33">
                    <button class="col-button col-button--big" @click="click_feedback($event); chg_value('5')"><span class="nichtmarkierbar">5</span></button>
                  </f7-col>
                  <f7-col class="button-column" width="33">
                    <button class="col-button col-button--big" @click="click_feedback($event); chg_value('6')"><span class="nichtmarkierbar">6</span></button>
                  </f7-col>
                </f7-row>
                <f7-row no-gap>
                  <f7-col class="button-column" width="33">
                    <button class="col-button col-button--big" @click="click_feedback($event); chg_value('1')"><span class="nichtmarkierbar">1</span></button>
                  </f7-col>
                  <f7-col class="button-column" width="33">
                    <button class="col-button col-button--big" @click="click_feedback($event); chg_value('2')"><span class="nichtmarkierbar">2</span></button>
                  </f7-col>
                  <f7-col class="button-column" width="33">
                    <button class="col-button col-button--big" @click="click_feedback($event); chg_value('3')"><span class="nichtmarkierbar">3</span></button>
                  </f7-col>
                </f7-row>
                <f7-row no-gap>
                  <f7-col class="button-column" width="33">
                    <button class="col-button col-button--big" @click="click_feedback($event); chg_value('0')"><span class="nichtmarkierbar">0</span></button>
                  </f7-col>
                  <f7-col class="button-column" width="33">
                    <button class="col-button col-button--big" @click="click_feedback($event); chg_value('.')"><span class="nichtmarkierbar" style="font-weight: 900;">.</span></button>
                  </f7-col>
                  <f7-col class="button-column" width="33">
                    <button class="col-button col-button--big" 
                      @mousedown="start_remove();" @mouseleave="stop_remove();" @mouseup="stop_remove();" @touchstart="start_remove()" @touchend="stop_remove()" @touchcancel="stop_remove()"
                      @click="click_feedback($event); chg_remove()"><span><f7-icon material="keyboard_backspace"></f7-icon></span></button>
                  </f7-col>
                </f7-row>
              </f7-col>
            </f7-row>

            <!--Botton buttons-->
            <f7-row class="keyboard__buttons" no-gap>
            <f7-col class="button-column">
              <button class="col-button" @click="click_feedback($event); chgbackward($event)">
                <f7-icon material="keyboard_arrow_left"></f7-icon>
              </button>
            </f7-col>
            <f7-col class="button-column">
              <button class="col-button" @click="click_feedback($event); chgforward($event)">
                <f7-icon material="keyboard_arrow_right"></f7-icon>
              </button>
            </f7-col>
            <f7-col class="button-column">
              <button class="col-button" @click="click_feedback($event); addcomment($event)">
                <f7-icon material="note_add"></f7-icon>
              </button>
            </f7-col>
            <f7-col class="button-column" v-if="activelog > 0">
              <button class="col-button" @click="click_feedback($event); addklammer(activelog)">
                <f7-icon material="link"></f7-icon>
              </button>
            </f7-col>
            <f7-col class="button-column" v-if="activelog <= 0">
              <button class="col-button" @click="sheetOpened = !sheetOpened" v-bind:style="[log.comment ? {'background-color': 'lightsalmon'}: {}]">
                <f7-icon material="note"></f7-icon>
              </button>
            </f7-col>
            <f7-col class="button-column">
              <button class="col-button" style="background-color: greenyellow;" @click="click_feedback($event); savelog($event)">
                <f7-icon material="keyboard_return"></f7-icon>
              </button>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>

    </f7-toolbar>
    <f7-row style="margin-bottom: 330px">
        <f7-col>
            <f7-block>
                <f7-row no-gap>
                    <f7-col width="10">BA</f7-col>
                    <f7-col width="15">#</f7-col>
                    <f7-col width="15">L</f7-col>
                    <f7-col width="15">DM</f7-col>
                    <f7-col width="15">Rind</f7-col>
                    <f7-col width="15">FM</f7-col>
                    <f7-col width="10">Q</f7-col>
                </f7-row>
                <f7-row no-gap
                    v-for="(loge) in logs.slice(0, limit)"
                    v-bind:key="loge.id"
                    @click='editLog(loge)'
                    :class="[{ 'highlight' : loge.id == activelog}, { 'zebra' : loge.id != activelog}]"
                    style="border-bottom: 1px solid lightgrey"
                    >
                    <f7-col width="10" style="border-right: 1px solid lightgrey">{{loge.baumart}}</f7-col>
                    <f7-col width="15" style="border-right: 1px solid lightgrey">{{loge.plaettchen}}</f7-col>
                    <f7-col width="15" style="border-right: 1px solid lightgrey">{{loge.laenge}} <span v-if="loge.laenge > 25" style="background-color" >⚠️</span></f7-col>
                    <f7-col width="15" style="border-right: 1px solid lightgrey;">{{loge.durchmesser}} <span v-if="loge.durchmesser < 10 || loge.durchmesser == null || loge.durchmesser > 115" style="background-color" >⚠️</span></f7-col>
                    <f7-col width="15" style="border-right: 1px solid lightgrey">{{loge.rabzug}}</f7-col>
                    <f7-col width="15" style="border-right: 1px solid lightgrey">{{loge.fm}}</f7-col>
                    <f7-col width="5">{{loge.qualitaet}}</f7-col>
                    <template  v-if="loge.comment">
                      <f7-col width="100" style="text-align:left; padding-left: 10px" ><i>{{loge.comment}}</i></f7-col>
                    </template>
                </f7-row>
            </f7-block>
        </f7-col>
    </f7-row>
    <TagSheet 
      :sheetOpened.sync="sheetOpened"
        @tag="log.comment = $event; comment = $event"
    />
  </f7-page>
</template>
<script>

import rabzugjson from '@/assets/rabzug.json'
import TagSheet from '@/components/TagSheet.vue'
export default {
  rabzuege: rabzugjson,
  components: {
    TagSheet
  },
  data: function () {
    return {
      test: '333',
      log: {
        polter_id: parseInt(this.$f7route.params.polter),
        stammnr: null,
        durchmesser: null,
        qualitaet: null,
        laenge: null,
        baumart: null,
        comment: null,
        anzahl: 1,
        fm: 0,
        fmir: 0,
        rabzug: 0,
        staerke: null
      },
      polter_id: (this.$f7route.params.polter),
      polter: { polter: null },
      logs: [],
      limit: 50,
      qualitaeten: ['A', 'AB', 'B', 'BC', 'C', 'CD', 'D', 'SK', 'BK', 'SW'],
      baumartenDefault: ['AH', 'BA', 'BU', 'EI', 'ES', 'FH', 'FÖ', 'HB', 'FI', 'LÄ', 'LI', 'SA', 'TA', 'LBH', 'NDH'],
      baumartenSettings: JSON.parse(localStorage.holzarten || '[]'),
      focuselmt: null,
      activelog: 0,
      click_feedback_timout: 0,
      interval: false,
      sheetOpened: false,
      comment: null
    }
  },
  mounted () {
    this.$f7ready(async (f7) => {
      this.polter = await this.$dbnew.getPolterbyId(parseInt(this.polter_id))
      this.logs = await this.$dbnew.getLogsbyPolterid(parseInt(this.polter_id))

      // Wähle letzte Baumart
      var lastlog = this.logs[0]
      if (typeof (lastlog) !== 'undefined') {
        this.log.baumart = lastlog.baumart
      }

      // Letzte Stammnummer der Liste
      var list = await this.$dbnew.getListbyId(this.polter.list_id)
      if (list.lastlog > 0) {
        this.log.stammnr = parseInt(list.lastlog) + 1
      }

      this.focuselmt = 'laenge'
      this.$refs.laenge.$refs.inputEl.focus()
    })
  },
  watch: {
    async bluetooth_data(data) {
        if (data && data.substr(0,2) === '$2' && data.includes("\n") === true) {
            var line = data.split("\n")
            line = line.map(x => x.split(";"))
            line = line.map(x => x.map(e => typeof e === 'string' ? e.trim() : typeof e))
            line.forEach(async (l) => {
              if (l.length > 1) {
                this.log.stammnr = parseInt(l[6])
                this.log.durchmesser = parseInt(l[10])
                this.log.qualitaet = l[8]
                this.log.laenge = parseFloat(l[9])
                this.log.baumart = l[7].toUpperCase();
                this.log.comment = (l[11] !== 'Keine'?l[11]:null)
                this.comp_rabzug
                this.comp_staerke
                this.comp_volume
                await this.savelog()
              }
            })
            this.bluetooth_data = ''
        } else if (data && data.substr(0,2) != '$2' && data.includes("\n")) {
            this.$f7.dialog.alert("Falsche Daten - Bist du in der Stehenderfassung?")
        }
    }
    },
  computed: {
    baumarten: function () {
      if(this.baumartenSettings.length === 0) {
        return this.baumartenDefault
      } else {
        return this.baumartenSettings
                .filter(ha => ha.liegend === true)
                .reduce(function(acc, cur) {
                        acc.push(cur.holzart);
                        return acc;
                        }, [])
      }
    },
    comp_volume: function () {
      var fm = 0;
      if (this.log.durchmesser != null && this.log.rabzug != null && this.log.laenge != null) {
        var durchmesser = parseFloat(this.log.durchmesser)
        var rabzug = parseFloat(this.log.rabzug)
        var laenge = parseFloat(this.log.laenge)

        fm = this.round(3.1416 / 4 * Math.pow(((durchmesser - rabzug) / 100), 2) * laenge, 3)
        var fmir = this.round(3.1416 / 4 * Math.pow(((durchmesser) / 100), 2) * laenge, 3)

        this.log.fm = fm
        this.log.fmir = fmir
      } else {
        fm = 0
      }
      return fm
    },
    comp_staerke: function () {
      var staerke
      if (this.log.durchmesser != null && this.log.rabzug != null) {
        var durchmesser = parseFloat(this.log.durchmesser)
        var rabzug = parseFloat(this.log.rabzug)
        var dm = durchmesser - rabzug
        staerke = ''
        if (dm < 10) { staerke = '1' } else
        if (dm < 15) { staerke = '1a' } else
        if (dm < 20) { staerke = '1b' } else
        if (dm < 25) { staerke = '2a' } else
        if (dm < 30) { staerke = '2b' } else
        if (dm < 35) { staerke = '3a' } else
        if (dm < 40) { staerke = '3b' } else
        if (dm < 45) { staerke = '4a' } else
        if (dm < 50) { staerke = '4b' } else
        if (dm < 55) { staerke = '5a' } else
        if (dm < 60) { staerke = '5b' } else
        if (dm > 59) { staerke = '6' } else {
          staerke = ''
        }
      } else {
        staerke = ''
      }
      this.log.staerke = staerke
      return staerke
    },
    comp_rabzug: function () {
      var rabzug = 0
      var abzuege = this.$options.rabzuege

      if (this.log.baumart != null & this.log.durchmesser != null) {
        if (typeof (abzuege[this.log.baumart]) === 'undefined') {
          this.log.rabzug = 0
          return ' BA unbekannt'
        }
        rabzug = abzuege[this.log.baumart][this.log.durchmesser]
        if (rabzug > 0) {
          this.log.rabzug = rabzug
        } else {
          this.log.rabzug = 0
          rabzug = 'n/a'
        }
      }

      return rabzug + ' cm'
    },
    comp_totale: function () {
      var fm = 0
      var anz = 0
      if (this.logs != null) {
        this.logs.forEach((log, i) => {
          fm = fm + log.fm
          anz = anz + 1
        })
      }
      return this.round(fm, 3) + 'fm / ' + anz + ' Stämme'
    },
    bluetooth_data: {
        get () {
            return this.$root.bluetooth_data
        },
        set() {
            this.$root.bluetooth_data = ""
        }
    },
    bluetooth_device: function() {
        return localStorage.bluetooth || null
    },
  },
  methods: {
    round (x, n) {
      var a = Math.pow(10, n)
      return (Math.round(x * a) / a)
    },
    async delLog (logid) {
      this.$dbnew.delLogbyId(parseInt(logid))
      this.focuselmt = 'durchmesser'
      
      this.log.durchmesser = null
      this.log.stammnr = parseInt(this.log.stammnr) + 1
      this.log.fm = 0
      this.log.fmir = 0
      this.activelog = 0

      this.logs = await this.$dbnew.getLogsbyPolterid(parseInt(this.polter_id))
    },
    loadMore() {
        this.limit = this.limit + 50
    },
    addcomment () {
      var self = this
      this.$f7.dialog.prompt('Stammkommentar', this.log.stammnr, function (comment) {
        if (comment == '') {
          self.log.comment = null
        } else {
          self.log.comment = comment
        }
      }, null, this.log.comment)
    },

    async addklammer (activelog) {
      if (this.log.stammnr.toString().indexOf('-') === -1) {
        // Hauptstamm (Count = 1)
        var stamm = this.log.stammnr
        this.log.stammnr = this.log.stammnr + '-1'
        await this.savelog()
        this.log.stammnr = stamm + '-2'
      } else {
        this.log.stammnr = this.log.stammnr.toString().slice(0, -1) + (parseInt(this.log.stammnr.toString().slice(-1)) + 1)
        this.activelog = 0
      }
      this.log.qualitaet = null
      this.log.durchmesser = null
      this.log.laenge = null
      this.log.anzahl = 0
    },

    editLog (slog) {
      if (this.activelog === parseInt(slog.id) && this.activelog > 0) {
        this.activelog = 0
        this.focuselmt = 'durchmesser'
        this.log.durchmesser = null
        this.log.stammnr = null
        this.log.fm = 0
        this.log.fmir = 0
        this.log.comment = this.comment || null
      } else {
        this.activelog = parseInt(slog.id)
        this.log = {...slog}
        this.log.stammnr = slog.plaettchen
      }
    },
    async savelog () {

      if (this.log.durchmesser == null) {
        this.$f7.dialog.alert('Bitte Durchmesser eingeben', 'Fehler')
        return
      }

      if (this.log.laenge == null) {
        this.$f7.dialog.alert('Bitte Länge eingeben', 'Fehler')
        return
      }

      if (this.log.durchmesser < 10 || this.log.durchmesser > 100) {
        this.$f7.dialog.alert('Stammdurchmesser ist nicht plausibel (10-100cm) - Stamm wird trotzdem gespeichert', 'Information')
      }

      if (this.log.laenge > 25) {
        this.$f7.dialog.alert('Stammlänge ist nicht plausibel (>25m) - Stamm wird trotzdem gespeichert', 'Information')
      }

      if(this.log.qualitaet == null) {
        this.$f7.dialog.alert('Qualität ist leer - Stamm wird trotzdem gespeichert', 'Information')
      }

      this.log.plaettchen = this.log.stammnr
      delete this.log.stammnr
      delete this.log.id
      
      if (this.activelog === 0) {
        await this.$dbnew.addLog(this.log)
      } else {
        var data = await this.$dbnew.updateLogbyId(parseInt(this.activelog),this.log)
        this.activelog = 0
      }
      
      // Update Stammnummer 
      if (this.log.plaettchen) {
        if (this.log.plaettchen.toString().indexOf('-') === -1) {
          await this.$dbnew.updateListbyId(parseInt(this.polter.list_id), { lastlog: this.log.plaettchen })
        }
      }
      this.log.stammnr = parseInt(this.log.plaettchen || 0) + 1
      delete this.log.plaettchen
      this.focuselmt = 'durchmesser'
      this.log.durchmesser = null
      this.log.fm = 0
      this.log.fmir = 0
      this.log.comment = this.comment || null
      this.log.anzahl = 1

      this.logs = await this.$dbnew.getLogsbyPolterid(parseInt(this.polter_id))
    },
    searchlog() {
      var that = this
      this.$f7.dialog.prompt('Stammnummer', 'Suche', function (stammnr) {
        console.log(that.logs)
        const result = that.logs.findIndex(log => log.plaettchen == stammnr.toString());
        if (result >= 0) {
          console.log('result', result)
          that.activelog = parseInt(that.logs[result].id)
          that.log = {...that.logs[result]}
          that.log.stammnr = stammnr.toString()
        } else {
          that.$f7.dialog.alert('Stammnummer nicht gefunden');
        }
      })
    },
    chg_baumart (baumart, fselt) {
      this.log.baumart = baumart
    },
    chg_qualitaet (qualitaet) {
      this.log.qualitaet = qualitaet
    },
    chg_value (wert) {
      if (this.log[this.focuselmt] == null) {
        this.log[this.focuselmt] = ''
      }
      this.log[this.focuselmt] = this.log[this.focuselmt] + wert
      this.$refs[this.focuselmt].$refs.inputEl.focus()
      this.$forceUpdate();
    },
    chg_remove (ev) {
      if (this.log[this.focuselmt] == null) {
        this.log[this.focuselmt] = ''
      }
    
      this.log[this.focuselmt] = this.log[this.focuselmt].toString().slice(0, -1)
      this.$refs[this.focuselmt].$refs.inputEl.focus()
      this.$forceUpdate();

    },
    click_feedback(ev) {
      const el = ev.currentTarget;
      el.classList.remove('clicked');
      el.classList.add('clicked');
      this.click_feedback_timout = setTimeout(() => {
        el.classList.remove('clicked');
      }, 500)
    },
    chgforward () {
      switch (this.focuselmt) {
        case 'stammnr':
          this.focuselmt = 'laenge'
          break
        case 'laenge':
          this.focuselmt = 'durchmesser'
          break
        case 'durchmesser':
          this.focuselmt = 'stammnr'
          break
      }
    },
    chgbackward () {
      switch (this.focuselmt) {
        case 'stammnr':
          this.focuselmt = 'durchmesser'
          break
        case 'laenge':
          this.focuselmt = 'stammnr'
          break
        case 'durchmesser':
          this.focuselmt = 'laenge'
          break
      }
    },
    handleBlur (e) {
      this.focuselmt = e
    },
    handleFocus (e) {
      this.focuselmt = e
      document.activeElement.blur()
    },
    start_remove(){
      var that = this
    	if(!this.interval){
      	this.interval = setInterval(() => that.chg_remove(), 70)	
      }
    },
    stop_remove(){
    	clearInterval(this.interval)
      this.interval = false
    }
  }
}
</script>
<style lang="less">
  .keyboard {
    height: auto !important;
    background: #FFFFFF !important;
    color: black;

    > .toolbar-inner {
      position: static;
      padding: 10px;
    }

    &__container {
      height: 100%;
      align-items: stretch;
      max-width: 600px;
      margin: 0 auto;
    }
    &__container-inner {
      display: flex;
      flex-direction: column;

      > div {
        padding: 0 0 10px;

        &:last-child {
          padding: 0;
        }
      }
    }

    &__inputs {
      .col {
        padding: 0 5px 0 0;

        &:last-child {
          padding: 0;
        }

        input {
          font-size: 13px;
        }
      }
    }

    &__info {
      [class*="col-"] {
        white-space: nowrap;

        &:last-child {
          text-align:right;
        }
      }
    }

    &__keyboard {
      margin: 0 -5px;

      > .col {
        height: 100%;
        padding: 0 5px;
      }

      i.icon {
        display: inline-block;
      }

      &-tree {
        width: 15%!important;

        > .row {
          overflow-y: scroll;
          overflow-x: hidden;
          width: 100%;
          height: 200px;
          min-height: 100%;
        }
      }

      &-treeextend {
        width: 45%!important;

        > .row {
          overflow-y: scroll;
          overflow-x: hidden;
          width: 100%;
          height: 200px;
          min-height: 100%;
        }
      }

      &-quality {
        width: 30%!important;

        > .row {
          height: 100%;
        }
      }

      &-numblock {
        width: 55%!important;
        height: 33.3%;
      }
      &-scrollnumblock {
        width: 55%!important;
        height: 33.3%;
        > .row {
          overflow-y: scroll;
          overflow-x: hidden;
          width: 100%;
          height: 200px;
          min-height: 100%;
        }
      }
    }

    &__buttons {
      justify-content: flex-start;

     .button-column {
        &:nth-child(1),
        &:nth-child(2) {
          width: calc(22.5% - 2.5px);
        }
        &:nth-child(2) {
           margin-right: 10px;
        }
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          width: calc(18.3333% - 2px);
        }
        &:last-child {
          margin-right: 0;
        }
     }
   }
  }

  .button-column {
    padding: 2px;
  }

  .col-button {
    border: 1px solid black;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: calc(20% - 5px);
    min-height: 40px;
    font-size: 20px;
    border-radius: 4px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
    &.clicked {
      animation: flashup 0.5s ease forwards
    }
    

    &--big {
      min-height: 51px;
    }
  }

  @keyframes flashup {
    0% {
      background-color: darkgrey;
    }
    20% {
      background-color: darkgrey;
    }
    10% {
      background-color: grey;
    }
  }
</style>
