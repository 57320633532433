<template>
  <f7-page
    name="bluetooth"
    @page:beforeout="
      {
        saveWakeLock();
      }
    "
  >
    <f7-navbar title="Bluetooth-Geräte" back-link="Back"></f7-navbar>
    <f7-block> </f7-block>
    <f7-block-header>Gerät</f7-block-header>
    <f7-block strong>
      <f7-row v-if="$root.bluetooth || ($root.bluetooth && !$root.bluetooth.name)">
        <f7-col width="20" />
        <f7-col width="60"
          ><f7-button
            class="col"
            round
            fill
            title="Connect Bluetooth"
            @click="$root.connect_bluetooth()"
            v-if="!$root.bluetooth_connected"
            >Kluppe verbinden</f7-button
          ></f7-col
        >
        <f7-col width="20" />
      </f7-row>
      <template v-if="$root.bluetooth_connected">
        <b>Name: </b> {{ $root.bluetooth.name }}<br />
      </template>
      <template v-if="$root.bluetooth_connected">
        <b>ID: </b> {{ $root.bluetooth.id }}<br />
      </template>
      <template>
        <b>Verbunden: </b> <span v-if="$root.bluetooth_connected">ja</span>
        <span v-else>nein</span><br />
      </template>
      <f7-row v-if="$root.bluetooth_connected">
        <f7-col width="20" />
        <f7-col width="60"
          ><f7-button
            v-if="$root.bluetooth_connected"
            class="col"
            round
            title="Disconnect Bluetooth"
            @click="$root.disconnect_bluetooth()"
            >Gerät trennen</f7-button
          ></f7-col
        >
        <f7-col width="20" />
      </f7-row>
      <b>Status:</b> {{ $root.message }}
    </f7-block>

    <!--<f7-block-header>Bildschirm ein</f7-block-header>
    <f7-block strong>
      <f7-row>
        <f7-col width="20" />
        <f7-col width="60">
          <f7-button
            v-if="wakeLockStatus === 'aus' || !wakeLockObj"
            class="col"
            fill
            round
            title="Bildschirm ein"
            @click="setWakeLock()"
            >Bildschirm anlassen</f7-button
          >
          <f7-button
            v-if="wakeLockStatus !== 'aus' || wakeLockObj"
            class="col"
            round
            title="Bildschirm aus"
            @click="releaseWakeLock()"
            >Bildschirm ausschalten</f7-button
          >
        </f7-col>
        <f7-col width="20" />
      </f7-row>
      <b>Status:</b> {{ wakeLockStatus }}
    </f7-block>-->

    <f7-block-header>Mitteilungen</f7-block-header>
    <f7-block strong>
      <f7-row>
        <p>Erlaube die Benachrichtigungen, damit die App dich über Bluetooth-Daten informieren kann.
          </p>
      </f7-row>
      <f7-row>
        <f7-col width="20" />
        <f7-col width="60">
          <f7-button
            v-if="notifcationStatus === 'unbekannt' || notifcationStatus === 'default'"
            class="col"
            fill
            round
            title="Mitteilungen erlauben"
            @click="requestPermission()"
            >Mitteilungen erlauben</f7-button
          >
        </f7-col>
        <f7-col width="20" />
      </f7-row>
      <b>Status:</b> {{ notifcationStatus }}
    </f7-block>
  </f7-page>
</template>
<script>
export default {
  data: function () {
    return {
      wakeLockObj: null,
      wakeLockStatus: "aus",
      notifcationStatus: null,
    };
  },
  mounted() {
    this.wakeLockObj = this.$root.wakeLockObj || null;
    if (this.wakeLockObj && !this.wakeLockObj.released) {
      this.wakeLockStatus = "eingeschalten";
    }
    this.notifcationStatus = Notification.permission || "unbekannt";
  },
  unmounted() {
    this.saveWakeLock();
  },

  methods: {  
    setWakeLock() {
      // Screen Wake Lock
      if (this.wakeLockObj) {
        this.wakeLockObj.release();
        this.wakeLockObj = null;
        this.wakeLockStatus = "aus";
        console.log("ausgeschaltet");
      } else {
        this.wakeLockStatus = "bereite auf...";
        navigator.wakeLock
          .request("screen")
          .then((wakeLock) => {
            this.wakeLockObj = wakeLock;

            this.wakeLockObj.addEventListener("release", () => {
              this.wakeLockStatus = "extern freigegeben";
              console.log("released");
              this.wakeLockObj = null;
            });

            this.wakeLockStatus = "eingeschaltet";
          })
          .catch((err) => {
            console.error(err);
            this.wakeLockStatus = "Fehler beim aufbereiten: " + err.message;
          });
      }
    },
    releaseWakeLock() {
      if (this.wakeLockObj) {
        this.wakeLockObj.release();
        this.wakeLockObj = null;
        this.wakeLockStatus = "aus";
      }
    },
    saveWakeLock() {
      console.log("saveWakeLock");
      console.log(this.wakeLockObj);
      this.$root.wakeLockObj = this.wakeLockObj;
    },

    requestPermission() {
      if (!('Notification' in window)) {
        alert('Mitteilungen werden von deinem Browser nicht unterstützt.');
        return;
      }

      var that = this
      
      Notification.requestPermission(function (result) {
        console.log(result)
        that.notifcationStatus = result;
      });
    },
  },
};
</script>
