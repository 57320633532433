<template>
    <div>
    <f7-row class="keyboard__keyboard">
        <f7-col :class="[{ 'keyboard__keyboard-treeextend' : tarif}, { 'keyboard__keyboard-tree' : !tarif}]">
            <f7-row no-gap>
            <f7-col class="button-column" :width="tarif?'33':'100'" v-for="baum in baumarten" :key="baum">
                <button class="col-button" @click="click_feedback($event); chg_baumart(baum, this)"><span class="nichtmarkierbar">{{ baum }}</span></button>
            </f7-col>
            </f7-row>
        </f7-col>
        <f7-col class="keyboard__keyboard-treeheight" v-if="!tarif">
            <f7-row no-gap>
            <f7-col class="button-column" width="50" v-for="ks in baumhöhe" :key="ks">
                <button class="col-button" @click="click_feedback($event); chg_laenge(ks)"><span class="nichtmarkierbar">{{ ks }}</span></button>
            </f7-col>
            </f7-row>
        </f7-col>
        <f7-col class="keyboard__keyboard-numblock" v-if="!kluppe">
            <f7-row no-gap>
            <f7-col class="button-column" width="33">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_value('7')"><span class="nichtmarkierbar">7</span></button>
            </f7-col>
            <f7-col class="button-column" width="33">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_value('8')"><span class="nichtmarkierbar">8</span></button>
            </f7-col>
            <f7-col class="button-column" width="33">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_value('9')"><span class="nichtmarkierbar">9</span></button>
            </f7-col>
            </f7-row>
            <f7-row no-gap>
            <f7-col class="button-column" width="33">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_value('4')"><span class="nichtmarkierbar">4</span></button>
            </f7-col>
            <f7-col class="button-column" width="33">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_value('5')"><span class="nichtmarkierbar">5</span></button>
            </f7-col>
            <f7-col class="button-column" width="33">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_value('6')"><span class="nichtmarkierbar">6</span></button>
            </f7-col>
            </f7-row>
            <f7-row no-gap>
            <f7-col class="button-column" width="33">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_value('1')"><span class="nichtmarkierbar">1</span></button>
            </f7-col>
            <f7-col class="button-column" width="33">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_value('2')"><span class="nichtmarkierbar">2</span></button>
            </f7-col>
            <f7-col class="button-column" width="33">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_value('3')"><span class="nichtmarkierbar">3</span></button>
            </f7-col>
            </f7-row>
            <f7-row no-gap>
            <f7-col class="button-column" width="33">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_value('0')"><span class="nichtmarkierbar">0</span></button>
            </f7-col>
            <f7-col class="button-column" width="33">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_value('.')"><span class="nichtmarkierbar" style="font-weight: 900;">.</span></button>
            </f7-col>
            <f7-col class="button-column" width="33">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_remove()"><span><f7-icon material="keyboard_backspace"></f7-icon></span></button>
            </f7-col>
            </f7-row>
        </f7-col>
        <f7-col class="keyboard__keyboard-scrollnumblock" v-if="kluppe">
            <f7-row no-gap>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('1')"><span class="nichtmarkierbar">1</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('2')"><span class="nichtmarkierbar">2</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('3')"><span class="nichtmarkierbar">3</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('4')"><span class="nichtmarkierbar">4</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('5')"><span class="nichtmarkierbar">5</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('6')"><span class="nichtmarkierbar">6</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('7')"><span class="nichtmarkierbar">7</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('8')"><span class="nichtmarkierbar">8</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('9')"><span class="nichtmarkierbar">9</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('10')"><span class="nichtmarkierbar">10</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('11')"><span class="nichtmarkierbar">11</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('12')"><span class="nichtmarkierbar">12</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('13')"><span class="nichtmarkierbar">13</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('14')"><span class="nichtmarkierbar">14</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('15')"><span class="nichtmarkierbar">15</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('16')"><span class="nichtmarkierbar">16</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('17')"><span class="nichtmarkierbar">17</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('18')"><span class="nichtmarkierbar">18</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('19')"><span class="nichtmarkierbar">19</span></button>
            </f7-col>
            <f7-col class="button-column" width="25">
                <button class="col-button col-button--big" @click="click_feedback($event); chg_valueks('20')"><span class="nichtmarkierbar">20</span></button>
            </f7-col>
            </f7-row>
        </f7-col>
    </f7-row>

        <!--Botton buttons-->
    <f7-row class="keyboard__buttons" no-gap>
        <f7-col class="button-column">
            <button class="col-button" @click="click_feedback($event); chgbackward($event)">
                <f7-icon material="keyboard_arrow_left"></f7-icon>
            </button>
        </f7-col>
        <f7-col class="button-column">
            <button class="col-button" @click="click_feedback($event); chgforward($event)">
                <f7-icon material="keyboard_arrow_right"></f7-icon>
            </button>
        </f7-col>
        <f7-col class="button-column">
            <button class="col-button" @click="click_feedback($event); addcomment($event)">
                <f7-icon material="note_add"></f7-icon>
            </button>
        </f7-col>
        <f7-col class="button-column" style="'visibility': 'hidden'">
            <button class="col-button" @click="click_feedback($event); chgkeyboard($event)" v-if="!tarif">
                <f7-icon material="keyboard"></f7-icon>
            </button>
        </f7-col>
        <f7-col class="button-column">
            <button class="col-button" style="background-color: greenyellow;" @click="click_feedback($event); saveentry($event)">
                <f7-icon material="keyboard_return"></f7-icon>
            </button>
        </f7-col>
    </f7-row>
    </div>
</template>
<script>
export default {
    props: ['kluppe', 'baumarten', 'baumhöhe', 'tarif'],
    methods: {
        click_feedback(ev) {
            const el = ev.currentTarget;
            el.classList.remove('clicked');
            el.classList.add('clicked');
            this.click_feedback_timout = setTimeout(() => {
                el.classList.remove('clicked');
            }, 500)
        },
        chg_baumart (baumart) {
            this.$emit('chgbaumart', baumart)
        },
        chg_laenge (laenge) {
            this.$emit('chglaenge', laenge)
        },
        chg_valueks(wert) {
            this.$emit('chgvalueks', (18+(4*(wert-1))))
        },
        chg_value(wert) {
            this.$emit('chgvalue', wert)
        },
        chg_remove() {
            this.$emit('chgremove')
        },
        chgkeyboard(ev){
            this.$emit('update:kluppe', !this.kluppe)
        },
        addcomment() {
            this.$emit('addcomment')
        },
        chgbackward() {
            this.$emit('chgbackward')
        },
        chgforward() {
            this.$emit('chgforward')
        },
        saveentry() {
            this.$emit('saveentry')
        }
    }
}
</script>