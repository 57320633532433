<template>
  <f7-page name="listform">
    <f7-navbar v-bind:title="edit? 'Liste aktualisieren': 'Liste erstellen'" back-link="Back" back-link-force back-link-url="/lists/"></f7-navbar>
    <form @submit.prevent="saveList">
      <f7-block-title>Holzliste</f7-block-title>
      <f7-list no-hairlines-md>
        <f7-list-input
          label="Holzlistennummer"
          type="text"
          placeholder="Holzlistennummer"
          :value="list.number"
          @input="list.number = $event.target.value"
          required
          validate
        ></f7-list-input>
        <f7-list-input
          type="textarea"
          label="Kommentar"
          placeholder="Kommentar"
          :value="list.comment"
          @input="list.comment = $event.target.value"
          clear-button
        ></f7-list-input>
      </f7-list>
      <f7-block>
          <f7-row>
              <f7-col width="20"/>
              <f7-col width="60"><f7-button class="col" round fill type="submit">{{ edit ? 'Liste aktualisieren': 'Liste erstellen' }}</f7-button></f7-col>
              <f7-col width="20"/>
          </f7-row>
      </f7-block>
    </form>
  </f7-page>
</template>
<script>
export default {
  data: function () {
    return {
      list :{
        number: null,
        comment: null,
      },
      list_id: this.$f7route.params.id,
      edit: false
    }
  },
  mounted () {
    this.$f7ready(async (f7) => {
      if (this.list_id != null) {
        this.edit = true
        this.list = await this.$dbnew.getListbyId(parseInt(this.list_id))
      }
    })
  },
  methods: {
    async saveList () {
      if (this.edit === false) {
        var data = await this.$dbnew.addList(this.list)
        this.$f7.views.current.router.navigate('/list/' + data + '/')
      } else {
        await this.$dbnew.updateListbyId(this.list_id, this.list)
        this.$f7.views.current.router.navigate('/lists/')
      }
    }
  }
}
</script>
