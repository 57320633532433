<template>
  <f7-page name="lists">
    <f7-navbar :sliding="false">
      <f7-nav-left>
        <f7-link
          icon-ios="f7:menu"
          icon-aurora="f7:menu"
          icon-md="material:menu"
          panel-open="left"
        ></f7-link>
      </f7-nav-left>
      <f7-nav-title sliding>MobiPolter</f7-nav-title>
    </f7-navbar>
    <f7-list media-list style="padding-bottom: 5rem">
      <f7-list-item
        v-for="list in lists"
        :key="list.id"
        :title="list.number"
        :badge="showPolterCnt(list.id)"
        :text="list.comment"
        :link="`/list/${list.id}/`"
        swipeout
        @swipeout:deleted="onDeleted(list.id)"
      >
        <f7-swipeout-actions left>
          <f7-swipeout-button
            delete
            confirm-text="Möchtest du wirklich die Liste, alle dazugehörigen Polter und Stämme löschen?"
            color="red"
          >
            <f7-icon
              material="delete_outline"
            ></f7-icon>
          </f7-swipeout-button>
        </f7-swipeout-actions>
        <f7-swipeout-actions right>
          <f7-swipeout-button @click="editList(list.id)">
            <f7-icon
              material="create"
            ></f7-icon>
          </f7-swipeout-button>
        </f7-swipeout-actions>
      </f7-list-item>
    </f7-list>
    <f7-fab
      position="center-bottom"
      slot="fixed"
      text="Liste erstellen"
      href="/listform/"
    >
      <f7-icon material="add"></f7-icon>
    </f7-fab>
  </f7-page>
</template>
<script>
export default {
  data: function () {
    return {
      lists: null,
      polter_cnt: []
    }
  },
  methods: {
    async onDeleted (listid) {
      const app = this.$f7
      await this.$dbnew.delListbyId(listid)
      app.dialog.alert('Holzliste erfolgreich gelöscht')
    },
    editList (listid) {
      this.$f7.views.current.router.navigate('/listform/' + listid + '/')
    },
    showPolterCnt(listid) {
      return (this.polter_cnt[parseInt(listid)] || 0) + ' Polter'
    }
  },
  mounted () {
    this.$f7ready(async (f7) => {

      var sort =JSON.parse(localStorage.settings)
      sort = sort?.sort?.liste || 'id'
      // Listen
      this.lists = await this.$dbnew.getLists(sort)

      // Stämme Count 
      this.polter_cnt = await this.$dbnew.getPolterPerLists();
    })
  }
}
</script>
