<template>
  <f7-page name="standingform">
    <f7-navbar title="Stehenderfassung" :subtitle="edit? 'Gruppe aktualisieren': 'Gruppe erstellen'" back-link="Back" back-link-force back-link-url="/standing/"></f7-navbar>
    <form @submit.prevent="saveStand">
      <f7-block-title>Gruppe für Stehenderfassung</f7-block-title>
      <f7-list no-hairlines-md>
          <f7-list-input
            label="Kurzbeschreibung"
            type="text"
            placeholder="Kurzbeschreibung"
            :value="stand.short_desc"
            @input="stand.short_desc = $event.target.value"
            required
            validate
          ></f7-list-input>
          <f7-list-input
            type="date"
            label="Schlagdatum"
            placeholder="geplantes Schlagdatum"
            :value="stand.forst_dt"
            @input="stand.forst_dt = $event.target.value"
          ></f7-list-input>
          <f7-list-item>
            <span>Tarife verwenden</span>
            <f7-toggle
            :checked="stand.tarif"
            @change="stand.tarif = $event.target.checked"
            :disabled="edit"
            ></f7-toggle>
          </f7-list-item>
          <f7-list-input
            v-if="stand.tarif"
            label="Tarif"
            type="select"
            :value="stand.tarife"
            :required="stand.tarif === true ? true : false"
            @input="stand.tarife = $event.target.value"
          >
            <option v-for="(k, i) in $options.tarife">{{i}}</option>
          </f7-list-input>
          <f7-list-input
            type="textarea"
            label="Kommentar"
            placeholder="Kommentar"
            :value="stand.comment"
            @input="stand.comment = $event.target.value"
            clear-button
          ></f7-list-input>
      </f7-list>
      <f7-block>
          <f7-row>
              <f7-col width="20"/>
              <f7-col width="60"><f7-button class="col" round fill type="submit">{{ edit ? 'Gruppe aktualisieren': 'Gruppe erstellen' }}</f7-button></f7-col>
              <f7-col width="20"/>
          </f7-row>
      </f7-block>
    </form>
  </f7-page>
</template>
<script>
import tarife from '@/assets/tarife.json'
export default {
  tarife: tarife,
  data: function () {
    return {
      stand :{
        short_desc: null,
        comment: null,
        forst_dt: null,
        tarif: false,
        tarife: null
      },
      stand_id: this.$f7route.params.id,
      edit: false
    }
  },
  mounted () {
    this.$f7ready(async (f7) => {
      if (this.stand_id != null) {
        this.edit = true
        this.stand = await this.$dbnew.getStandingbyId(parseInt(this.stand_id))
        this.tarife = this.stand.tarife
      }
    })
  },
  watch: {
    stand: {
      deep: true,
      handler(stand) {
        if (stand.tarif === false) {
          stand.tarife = ""
        }
        // Tarif-Wechsel
        if (stand.tarife !== this.tarife && this.edit === true) {
          var that = this
          this.$f7.dialog.confirm(
            "Bei einem Wechsel des Tarifes müssen alle Stämme neu berechnet werden. Dies kann einige Zeit dauern.",
            async function() {
              that.$f7.preloader.show()
              let entries = await that.$dbnew.getStandentriesbyStandingid(parseInt(that.stand_id))
              let tariftable = that.$options.tarife[stand.tarife]
              entries.forEach(entry => {
                entry.fm = tariftable[that.calc_kluppenstufe(entry.durchmesser)]
                that.$dbnew.updateStandingentrybyId(parseInt(entry.id),entry)
              })
              that.$dbnew.updateStandingbyId(that.stand_id, that.stand)
              that.$f7.preloader.hide()
              that.$f7.dialog.alert(entries.length + ' Stämme geändert. Bitte prüfe die Stämme!')
            }, 
            function() {
              stand.tarife = that.tarife
            }
          );
        }
      }
    }
  },
  methods: {
    async saveStand () {
      if (this.edit === false) {
        var data = await this.$dbnew.addStanding(this.stand)
        this.$f7.views.current.router.navigate('/standingentry/' + data + '/')
      } else {
        await this.$dbnew.updateStandingbyId(this.stand_id, this.stand)
        this.$f7.views.current.router.navigate('/standing/')
      }
    },
    calc_kluppenstufe(dm) {
      return Math.max(Math.ceil(((dm || 0)-15)/4),0)
    },
  }
}
</script>
