<template>
    <f7-row no-gap
        @click='editEntry(stands)'
        style="border-bottom: 1px solid lightgrey"
        :class="[{ 'highlight' : stands.id == activeentry}, { 'zebra' : stands.id != activeentry}]"
        >
        <f7-col width="15" style="border-right: 1px solid lightgrey; padding-left: 10px;">{{stands.baumart}}</f7-col>
        <f7-col v-if="!tarif" width="15" style="border-right: 1px solid lightgrey; text-align: right; padding-right: 10px;">{{stands.laenge}}</f7-col>
        <f7-col width="10" style="border-right: 1px solid lightgrey; text-align: right; padding-right: 10px;">{{stands.durchmesser}}</f7-col>
        <f7-col width="10" style="border-right: 1px solid lightgrey; text-align: right; padding-right: 10px;">{{calc_kluppenstufe(stands.durchmesser)}}</f7-col>
        <f7-col width="15" style="border-right: 1px solid lightgrey; text-align: right; padding-right: 10px;">{{stands.fm}}</f7-col>
        <f7-col width="35" style="border-right: 1px solid lightgrey; text-align: left; padding-left: 10px;">{{stands.tag}}</f7-col>
        <f7-col width="5"><f7-icon v-if="stands.comment" size="20px" material="more_vert"></f7-icon></f7-col>
    </f7-row>
</template>
<script>
export default {
    props: ['stands', 'activeentry', 'tarif'],
    data: function () {
        return {
            activeentry1: 0
        }
    },
    methods: {
        calc_kluppenstufe(dm) {
            return Math.max(Math.ceil(((dm || 0)-15)/4),0)
        },
        editEntry(stands) {
            this.$emit('editentry', stands)
        }
    }
}
</script>