<template>
    <f7-sheet class="tag-sheet" :opened="sheetOpened" @sheet:closed="$emit('update:sheetOpened', false)" >
        <f7-toolbar>
            <div class="left">Kommentare</div>
            <div class="right">
                <f7-link sheet-close>Schliessen</f7-link>
            </div>
        </f7-toolbar>
        <f7-page-content>
             <f7-list media-list>
                <f7-block>
                    <f7-row style="padding: 5px">
                        <f7-col width="60">
                            <f7-input
                                label="neuer Tag"
                                type="text"
                                outline
                                inputStyle="height:40px;font-size: 13px;"
                                placeholder="neuer Kommentar"
                                :value="newtag"
                                @input="newtag = $event.target.value"
                            ></f7-input>
                        </f7-col>
                        <f7-col width="40">
                            <f7-button class="button-raised" style="height:40px;" @click="save_tag()">Speichern</f7-button>
                        </f7-col>
                    </f7-row>
                    <f7-row style="padding: 5px">
                        <f7-col width="100">
                            <f7-button class="col button button-small button-fill color-gray" @click="chg_tag('')">kein Kommentar</f7-button>
                        </f7-col>
                    </f7-row>
                </f7-block>
                <f7-list-item 
                    v-for="(tags) in taglist" 
                    v-bind:key="tags"
                    :title="tags" link="#"
                    @click="chg_tag(tags)"
                    swipeout
                    @swipeout:deleted="del_tag(tags)">
                    <f7-swipeout-actions left>
                        <f7-swipeout-button
                            delete
                            confirm-text="Möchtest du den Kommentar wirklich löschen?"
                            color="red"
                            >
                            <f7-icon material="delete_outline"></f7-icon>
                        </f7-swipeout-button>
                    </f7-swipeout-actions>
                </f7-list-item>
            </f7-list>
        </f7-page-content>
    </f7-sheet>
</template>
<script>
export default {
    props: ['sheetOpened'],
    data: function () {
        return {
            newtag: null,
            taglist: [],
        }
    },
    mounted() {
        this.taglist =  JSON.parse(localStorage.kommentarlist ||'["-1m","-2m","Käfer","Wertholz"]');
    },
    watch: {
        taglist(newtaglist) {
            localStorage.kommentarlist = JSON.stringify(newtaglist);
        }
    },
    methods: {
        chg_tag(tags) {
            this.$emit('update:sheetOpened', false)
            this.$emit('tag', tags)
        },
        save_tag() {
            if (this.newtag =="" || this.newtag == null) {
                this.$f7.dialog.alert('Kommentar ist leer');
            } else {
                this.taglist.indexOf(this.newtag) === -1 ? this.taglist.push(this.newtag) : this.$f7.dialog.alert('Kommentar existiert bereit');
                this.$emit('tag', this.newtag)
                this.newtag = null
                this.$emit('update:sheetOpened', false)
            }
        },
        del_tag(tags) {
            this.taglist = this.taglist.filter(function(e) { return e !== tags })
        },
    },
}
</script>
<style lang="less">
.tag-sheet {
    max-height: 80vh;
    height: 60vh;

    input {
        padding-left: 5px !important
    }

    .left {
        padding-left: 10px;
        font-weight: 500;
    }
}
</style>