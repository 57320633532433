<template>
    <f7-page name="cam">
        <f7-navbar title="Bilder" v-bind:subtitle="'Polter ' + polter.polter" back-link-force v-bind:back-link-url="'/list/' + polter.list_id + '/'" back-link="Back">
        </f7-navbar>
            <input type="file" name="file" id="file" class="inputfile" capture ref="filedialog" @change="handleImage"/>
            <f7-fab position="center-bottom" slot="fixed" @click="openCam()">
                <label for="file">                
                    <f7-icon material="camera"></f7-icon>
                </label>
            </f7-fab>
            <f7-list media-list>
                <f7-list-item
                    v-for="(image, index) in images" :key="image.id" @click="$refs.page.open(index)"
                    swipeout
                    @swipeout:deleted="deleteImage(image.id)"
                >
                    <img slot="inner" :src="image.dataurl" width="100%" />
                    <f7-swipeout-actions left>
                        <f7-swipeout-button delete confirm-text="Möchtest du das Bild wirklich löschen?" color="red">
                            <f7-icon material="delete_outline"></f7-icon>
                        </f7-swipeout-button>
                    </f7-swipeout-actions>
                </f7-list-item>
            </f7-list>

        <f7-photo-browser
            :photos="photos"
            type="page"
            back-link-text="Back"
            ref="page"
          >
        </f7-photo-browser>
    </f7-page>
</template>
<script>
export default {
    data: function () {
        return {
            polter_id: (this.$f7route.params.polter),
            polter: [],
            images: [],
            photos: []
        }
    },
    methods: {
        openCam() {
            console.log(this.$refs.filedialog.click())
        },
        async getData() {
            this.polter = await this.$dbnew.getPolterbyId(this.polter_id)
            this.images = await this.$dbnew.getImagesbyPolterid(this.polter_id)
            this.photos = []
            this.images.map (e => this.photos.push({url: e.dataurl, caption: e.size+' kb'}))
        },
        async deleteImage(id) {
            await this.$dbnew.delImagebyId(id)
            this.getData
        },
        async handleImage(e) {
            var that = this;
            
            if (e.target.files.length < 1) {
                return;
            }

            const size = 1024;
            const fileName = e.target.files[0].name;
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = event => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const elem = document.createElement('canvas');

                    if (img.height > img.width) {
                        elem.height = size;
                        elem.width = elem.height * (img.width / img.height);
                    } else {
                        elem.width = size;
                        elem.height = elem.width * (img.height / img.width);
                    }
                            
                    const ctx = elem.getContext('2d');
                    ctx.drawImage(img, 0, 0, elem.width, elem.height);
                    var dataurl = ctx.canvas.toDataURL('image/jpeg', .9)
                    //document.getElementById("bild").setAttribute("src", dataurl)
                    //that.$refs.image.src=dataurl
                    //console.log('dataurl', dataurl)
                    var neu = (Math.round(dataurl.length*3/4/1024))
                    var orig = (Math.round(img.src.length*3/4/1024))
                    //document.getElementById("result").innerText= neu+'kb / '+orig+'kb';
                    //console.log(neu+'kb / '+orig+'kb')

                    this.$dbnew.addImage({ polter_id: parseInt(that.polter_id), dataurl: dataurl, size: neu})
                    that.getData()
                },
            reader.onerror = error => console.log(error);
            };
        }
    },
    mounted () {
        this.$f7ready(async (f7) => {
            this.getData();
        })
    },
}
</script>
<style lang="css" scoped>
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
</style>