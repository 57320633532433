<template>
  <f7-page name="lists">
    <f7-navbar v-bind:title="'Holzliste ' + list.number" back-link="Back" back-link-force back-link-url="/lists/" v-bind:subtitle="list.comment"></f7-navbar>
    <f7-list media-list style="padding-bottom: 5rem">
      <f7-list-item
        v-for="(polter) in polters"
        :key="polter.id"
        :title="polter.polter +' ['+(polter.sortiment || 'kein Sortiment') +']'"
        :badge="showBadge(polter)"
        :text= "showText(polter)"
        :link="polter.erfassungsmethode === 'log'? `/logform/${polter.id}/` : polter.erfassungsmethode === ''? `/logform/${polter.id}/` : polter.erfassungsmethode === 'section'? `/sectionform/${polter.id}/` : null"
        swipeout
        @swipeout:deleted="onDeleted(polter.id)"
        >
        <f7-icon v-if="polter.erfassungsmethode === 'log'" slot="media" f7="list_dash"></f7-icon>
        <f7-icon v-if="polter.erfassungsmethode === ''" slot="media" f7="list_dash"></f7-icon>
        <f7-icon v-if="polter.erfassungsmethode === 'section'" slot="media" f7="square_line_vertical_square_fill"></f7-icon>
        <f7-icon v-if="polter.erfassungsmethode === 'estimate'" slot="media" f7="wand_rays"></f7-icon>
          <f7-swipeout-actions left>
            <f7-swipeout-button delete confirm-text="Möchtest du wirklich den Polter und Stämme löschen?" color="red">
                <f7-icon material="delete_outline"></f7-icon>
            </f7-swipeout-button>
          </f7-swipeout-actions>
          <f7-swipeout-actions right>
            <f7-swipeout-button  @click="editPolter(polter.id)">
                <f7-icon material="create"></f7-icon>
            </f7-swipeout-button>
            <f7-swipeout-button  @click="openCam(polter.id)" color="green">
              <div><f7-icon material="camera"></f7-icon></div>
                <div style="padding-left: 1em">{{showImage(polter)}}</div>
            </f7-swipeout-button>
          </f7-swipeout-actions>
      </f7-list-item>
    </f7-list>
    <f7-fab position="center-bottom" slot="fixed" text="Polter hinzfügen" v-bind:href="'/polterform/' + list_id + '/'">
      <f7-icon material="add"></f7-icon>
    </f7-fab>
  </f7-page>
</template>
<script>
export default {
  data: function () {
    return {
      polters: null,
      list_id: this.$f7route.params.id,
      list: { number: null, comment: null },
      stats: []
    }
  },
  methods: {
    showBadge(polter) {
      var stats = this.stats[parseInt(polter.id)]

      if (polter.erfassungsmethode === 'log' && stats !== undefined) {
        stats = this.stats[parseInt(polter.id)]
        return parseInt(stats['fm'] || 0) + ' fm 	/ ' + (stats['cnt'] || 0) + ' Stück'
      }

      if (polter.erfassungsmethode === 'estimate') {
        return parseInt(polter.schaetzwert) + ' ' + (polter.einheit || ' ') + ' / ' + parseInt(polter.fm || 0) + ' fm'
      }

      if (polter.erfassungsmethode === 'section' && stats !== undefined) {
        return parseInt(stats['rm']) + ' rm / ' + parseInt(stats['fm'])  + ' fm'
      }
    },
    showText(polter) {
      if (polter.waldort) {
        return '[' + polter.waldort + ']' + (polter.comment || '')
      } else {
        return (polter.comment || '')
      }
    },
    showImage(polter) {
      var stats = this.stats[parseInt(polter.id)]
      if (stats !== undefined && stats['img'] !== undefined) {
        return stats['img'] + ' Bild(er)'
      }
    },

    async onDeleted (polterid) {
      const app = this.$f7
      await this.$dbnew.delPolterbyId(polterid)
      app.dialog.alert('Polter erfolgreich gelöscht')
    },
    editPolter (polterid) {
      this.$f7.views.current.router.navigate('/polterform/' + this.list_id + '/' + polterid + '/')
    },
    openCam(polter) {
      this.$f7.views.current.router.navigate('/cam/' + polter + '/')
    }
  },
  mounted () {
    this.$f7ready(async (f7) => {
      var sort =JSON.parse(localStorage.settings)
      sort = sort?.sort?.polter || 'id'
      this.polters = await this.$dbnew.getPoltersbyListid(this.list_id, sort)
      this.list = await this.$dbnew.getListbyId(this.list_id)
      this.stats = await this.$dbnew.getSumEntriesPerList(this.list_id)
    })
  }
}
</script>
