<template>
  <f7-page name="export">
    <f7-navbar title="Exportieren" back-link="Back" back-link-force back-link-url="/"
      subtitle="Listen ins MobiPolter Desktop hochladen"></f7-navbar>
    <f7-block strong>
      Zum Hochladen der Listen ist ein Account Voraussetzung. Registriere dich unter mobipolter.ch
    </f7-block>
    <f7-block-title>Auswahl der <b>Holzlisten</b></f7-block-title>
    <f7-block strong class="no-padding-horizontal">
      <f7-treeview>
        <f7-treeview-item opened v-for="(item, index) in lists" :key="index"
          :label="(item.number || '-').toString() + ' ' + (item.comment || '').toString().substring(0, 50)"
          icon-f7="document_text_fill">
          <f7-checkbox slot="content-start"
            :checked="Object.values(lists[index]['selected']).indexOf(false) <= 0 && Object.values(lists[index]['selected']).indexOf(true) >= 0"
            :indeterminate="Object.values(lists[index]['selected']).indexOf(false) >= 0 && Object.values(lists[index]['selected']).indexOf(true) >= 0"
            @change="(e) => {
          Object.keys(lists[index]['selected']).forEach(k => lists[index]['selected'][k] = e.target.checked);
          prepare_list();
        }" />
          <f7-treeview-item v-for="(polter, indexpolter) in item.polter" :key="indexpolter" icon-f7="location">
            <template #label>
              {{ (polter.polter || '-').toString() }} <small v-if="polter.export">(bereits exportiert)</small>
            </template>
            <f7-checkbox slot="content-start" :checked="lists[index]['selected'][indexpolter]" @change="((e) => {
          lists[index]['selected'][indexpolter] = e.target.checked
          prepare_list();
        })" />
          </f7-treeview-item>
        </f7-treeview-item>
      </f7-treeview>
    </f7-block>

    <f7-block-title>Auswahl der <b>Stehenderfassungsgruppen</b></f7-block-title>
    <f7-block strong class="no-padding-horizontal">
      <f7-treeview>
        <f7-treeview-item opened v-for="(item, index) in standing" :key="index" icon-f7="tree">
          <template #label>
            {{ item.short_desc }} <small v-if="item.export">(bereits exportiert)</small>
          </template>
          <f7-checkbox slot="content-start" :checked="standing[index]['selected']" @change="(e) => {
          standing[index]['selected'] = e.target.checked
          prepare_standing();
        }" />
        </f7-treeview-item>
      </f7-treeview>
    </f7-block>
    <f7-sheet class="demo-sheet-swipe-to-close" style="height:auto; --f7-sheet-bg-color: #fff;" backdrop>
      <f7-page-content>
        <f7-block-title>Login</f7-block-title>
        <f7-list form>
          <f7-list-input type="text" name="username" placeholder="Benutzernamen" :value="username"
            @input="username = $event.target.value"></f7-list-input>
          <f7-list-input type="password" name="password" placeholder="Passwort" :value="password"
            @input="password = $event.target.value"></f7-list-input>
        </f7-list>
        <f7-list>
          <f7-block-footer>
            {{ status }}
          </f7-block-footer>
        </f7-list>
        <f7-block>
          <f7-row>
            <f7-col width="20" />
            <f7-col width="60"><f7-button round fill title="Sign In" @click="liste_hochladen">Login</f7-button></f7-col>
            <f7-col width="20" />
          </f7-row>
        </f7-block>
      </f7-page-content>
    </f7-sheet>
    <f7-block>
      <f7-row>
        <f7-col width="20" />
        <f7-col width="60"><f7-button round fill sheet-open=".demo-sheet-swipe-to-close">Auswahl
            hochladen</f7-button></f7-col>
        <f7-col width="20" />
      </f7-row>
    </f7-block>
  </f7-page>
</template>
<script>
export default {
  data: function () {
    return {
      lists: {},
      standing: {},
      elists: [],
      username: localStorage.getItem('user'),
      password: null,
      status: null,
      loginfailure: false,
      url: process.env.VUE_APP_API_URL,
      //url: 'http://localhost:3000',
      polterkeys: [],
      standingkeys: []
    }
  },
  methods: {

    async liste_hochladen() {
      var data = await this.prepare_list()
      var data_standing = await this.prepare_standing()
      var that = this
      console.log('data', this.polterkeys)
      this.$http({ url: this.url + '/login', data: { email: this.username, password: this.password }, method: 'POST', withCredentials: true })
        .then(resp => {
          const token = resp.data.token
          localStorage.setItem('token', token)
          localStorage.setItem('user', this.username)
          that.$http.defaults.headers.common.Authorization = token
          this.status = 'Login erfolgreich'

          //hochladen der Listen
          if (data.length > 0) {
            that.$f7.dialog.preloader('Exportiere Listen...');
            that.$http({ url: this.url + '/mobipolter', data: { listen: data }, method: 'PUT', withCredentials: true })
              .then(resp => {
                that.$f7.dialog.close()
                this.status = resp.data
                that.$f7.dialog.alert('Die Stämme wurden erfolgreich hochgeladen. Lösche diese nach der Überprüfung im MobiPolter')

                that.polterkeys.forEach(async (polter_id) => {
                  that.$dbnew.updatePolterbyId(parseInt(polter_id), { export: true })
                })
              })
              .catch(err => {
                that.$f7.dialog.close()
                that.$f7.dialog.alert('Fehler beim hochladen der Stämme - ' + err + ' - Bitte versuche es erneut')
              })
          }

          //hochladen von Stehenderfassung
          if (data_standing.length > 0) {
            that.$f7.dialog.preloader('Exportiere Stehend...');
            that.$http({ url: this.url + '/mobipolter_stehend', data: { gruppen: data_standing }, method: 'PUT', withCredentials: true })
              .then(resp => {
                that.$f7.dialog.close()
                this.status = resp.data
                that.$f7.dialog.alert('Die Stehenderfassung wurden erfolgreich hochgeladen. Lösche diese nach der Überprüfung im MobiPolter')
                that.standingkeys.forEach(async (standing_id) => {
                  that.$dbnew.updateStandingbyId(parseInt(standing_id), { export: true })
                })
              })
              .catch(err => {
                that.$f7.dialog.close()
                that.$f7.dialog.alert('Fehler beim hochladen der Stehenderfassung - ' + err + ' - Bitte versuche es erneut')
              })
          }
          this.$f7.sheet.close('.demo-sheet-swipe-to-close');
        })
        .catch(err => {
          this.status = err
          this.loginfailure = true
          localStorage.removeItem('token')
        })
    },

    async prepare_standing() {
      var standingneu = []
      var that = this
      for (var stkey in this.standing) {
        if (this.standing[stkey].selected === true) {
          that.standingkeys.push(stkey)
          let estand = []
          estand = await this.$dbnew.getStandingbyId(parseInt(stkey))
          estand.entries = []
          estand.entries = await this.$dbnew.getStandentriesbyStandingid(parseInt(stkey))
          standingneu.push(estand)
        }
      }
      return standingneu
    },
    async prepare_list() {
      this.elists = []
      var listneu = []
      var that = this
      that.polterkeys = []
      for (var lkey in this.lists) {
        const polterkeys = []
        for (var pkey in this.lists[lkey].selected) {
          if (this.lists[lkey].selected[pkey] === true) {
            that.polterkeys.push(parseInt(pkey))
            polterkeys.push(parseInt(pkey))
          }
        }

        if (polterkeys.length > 0) {
          let elist = []

          elist = await this.$dbnew.getListbyId(parseInt(lkey))
          elist.polter = [];
          for await (var polter_id of polterkeys) {
            var tmppolter = []
            tmppolter = await this.$dbnew.getPolterbyId(parseInt(polter_id))
            tmppolter.logs = []
            tmppolter.logs = await this.$dbnew.getLogsbyPolterid(parseInt(polter_id))
            tmppolter.sections = []
            tmppolter.sections = await this.$dbnew.getSectionsbyPolteridforExport(parseInt(polter_id))
            tmppolter.images = []
            tmppolter.images = await this.$dbnew.getImagesbyPolterid(parseInt(polter_id))
            elist.polter.push(tmppolter)
          }

          listneu.push(elist)
        }
      }
      return listneu
    }
  },
  mounted() {
    this.$f7ready(async (f7) => {
      this.lists = await this.$dbnew.getListsPoltersforExport();
      this.standing = await this.$dbnew.getStandingforExport();
    })
  }
}
</script>
