import AboutPage from '../pages/about.vue';
import ListsPage from '../pages/lists.vue';
import ListPage from '../pages/list.vue';
import CamPage from '../pages/cam.vue';
import ListformPage from '../pages/listform.vue';
import LogformPage from '../pages/logform.vue';
import PolterformPage from '../pages/polterform.vue';
import SectionformPage from '../pages/sectionform.vue';
import StandingPage from '../pages/standing.vue';
import StandingformPage from '../pages/standingform.vue';
import StandingentryPage from '../pages/standingentry.vue';
import ObjectdetectionPage from '../pages/objectdetection.vue'
import BluetoothPage from '../pages/bluetooth.vue'
//import ObjectdetectionimagePage from '../pages/objectdetectionImage.vue'
const ObjectdetectionimagePage = () => import('../pages/objectdetectionImage.vue')
import ExportPage from '../pages/exports.vue';
import SettingsPage from '../pages/settings.vue';
import NotFoundPage from '../pages/404.vue';

import { f7 } from 'framework7-vue';

var routes = [
  {
    path: "/",
    redirect: "/lists/",
  },
  {
    path: "/about/",
    component: AboutPage,
  },
  {
    path: "/settings/",
    component: SettingsPage,
  },
  {
    path: "/standing/",
    component: StandingPage,
  },
  {
    path: "/standingform/:id?/",
    component: StandingformPage,
  },
  {
    path: "/standingentry/:stand/",
    component: StandingentryPage,
  },
  {
    path: "/lists/",
    component: ListsPage,
  },
  {
    path: "/export/",
    component: ExportPage,
  },
  {
    path: "/list/:id/",
    component: ListPage,
  },
  {
    path: "/listform/:id?/",
    component: ListformPage,
  },
  {
    path: "/polterform/:list/:id?/",
    component: PolterformPage,
  },
  {
    path: "/logform/:polter/",
    component: LogformPage,
  },
  {
    path: "/bluetooth/",
    secureComponent: BluetoothPage,
    module: "FO",
  },
  {
    path: "/cam/:polter/",
    component: CamPage,
  },
  {
    path: "/sectionform/:polter/",
    component: SectionformPage,
  },
  {
    path: "/objectdetection/",
    secureComponent: ObjectdetectionPage,
    module: "OD",
  },
  {
    path: "/objectdetection/:image/",
    secureComponent: ObjectdetectionimagePage,
    module: "OD",
  },
  {
    path: "(.*)",
    component: NotFoundPage,
  },
];

//Protected Routes
for(const route of routes) {
	if(route.secureComponent) {
		route.async = function(routeTo, routeFrom, resolve, reject) {
			if(JSON.parse(localStorage.modules ||'[]').includes(routeTo.route.module)) {

        if (typeof routeTo.route.secureComponent === 'function') {
          f7.dialog.preloader('Lade Komponenten... Das kann eine Weile dauern.');
          routeTo.route.secureComponent().then((vc) => {
            f7.dialog.close()
          })
        }
				const newRoute = { 
          path: routeTo.route.path, 
          component: routeTo.route.secureComponent,
        };
				resolve(newRoute);
			} else {
				reject();
        const router = this
        var sh = f7.sheet.get('.login-sheet')
        sh.open();
        sh.on("closed", () => {
          if (JSON.parse(localStorage.modules ||'[]').includes(routeTo.route.module)) {
            router.navigate(routeTo.route.path)
          }
        })
			}
		}
	}
}

export default routes;
